import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import styles from '../../opm-summary-waterfall.module.scss';
import React, { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  OpmSampleWaterfallDto,
  OpmSampleWaterfallTranche,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import { useLocale } from '@app/core/hooks/useLocale';
import { translationKeys } from '@locale/setupI18n';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
  toPercentageUsingFormatter,
} from '@app/shared/formatters';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { Operator, TargetMetric } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';

type ValueKeys = Omit<OpmSampleWaterfallTranche, 'conditions'>;

interface SampleWaterfallTranchesProps {
  sampleWaterfallResults: OpmSampleWaterfallDto;
}

const SampleWaterfallTranches: FC<SampleWaterfallTranchesProps> = ({
  sampleWaterfallResults,
}): JSX.Element => {
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };

  const createRow = (
    label: translationKeys,
    valueKey: keyof ValueKeys,
    trancheIndex: number,
    upper = false,
    isPercentage = false
  ) => {
    const tranches = sampleWaterfallResults.tranches;
    const trancheNames = Object.keys(tranches);

    if (trancheNames.length === 0 || tranches[trancheIndex][valueKey] === null) {
      return null;
    }

    const hasMultipleConditions = tranches[trancheIndex].conditions.length > 1;

    if (valueKey === 'activeHurdle' && !hasMultipleConditions) {
      return null;
    }

    const value = sampleWaterfallResults.tranches[trancheIndex][valueKey];
    return (
      <tr data-testid={`${valueKey}-tranche${trancheIndex + 1}-title`}>
        <td>{l(label)}</td>
        <td data-testid={`${valueKey}-tranche${trancheIndex + 1}`}>
          <CellValue
            className={
              upper
                ? value === false
                  ? styles['upper-text-value-false']
                  : styles['upper-text']
                : ''
            }
            value={isPercentage && typeof value === 'number' ? value * 100 : value}
            {...(isPercentage ? enteredPercentageViewRules : amountRules)}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      {Object.entries(sampleWaterfallResults.tranches).map((_, trancheIndex) => (
        <React.Fragment key={`tranche-${trancheIndex}`}>
          <tr>
            <th
              colSpan={2}
              className={styles['sub-section-header']}
              data-testid={`tranche-title-${trancheIndex}`}>
              {`${l('_Tranche')} ${trancheIndex + 1}`}
            </th>
          </tr>
          {createRow('_SponsorInvestment', 'sponsorInvestment', trancheIndex)}

          {Object.entries(sampleWaterfallResults.tranches)[trancheIndex][1].conditions.map(
            (condition, conditionIndex) => {
              return (
                <tr key={`condition-${conditionIndex}`}>
                  <td
                    data-testid={`condition${conditionIndex + 1}-tranche${trancheIndex + 1}-title`}>
                    {l('_SponsorConditionPlaceholders', {
                      metric:
                        condition.metric ===
                        enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                          ? l('_IRR')
                          : condition.metric ===
                            enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
                          ? l('_MoM')
                          : condition.metric,
                      operator:
                        condition.operator === enumKeyByValue(Operator, Operator.GreaterThan)
                          ? '>'
                          : condition.operator === enumKeyByValue(Operator, Operator.LessThan)
                          ? '<'
                          : condition.operator ===
                            enumKeyByValue(Operator, Operator.GreaterThanOrEqual)
                          ? '>='
                          : condition.operator ===
                            enumKeyByValue(Operator, Operator.LessThanOrEqual)
                          ? '<='
                          : condition.operator,
                      value:
                        condition.metric ===
                        enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                          ? toPercentageUsingFormatter(condition.testValue)
                          : numberValueFormatter({
                              value: condition.testValue,
                              ...calculatedMultipleValueFormatterProps,
                            }),
                      interpolation: interpolationOption,
                    })}
                  </td>
                  <td data-testid={`condition${conditionIndex + 1}-tranche${trancheIndex + 1}`}>
                    <CellValue
                      value={condition.equivalentHurdleValue}
                      alignment={Alignment.Right}
                      {...amountRules}
                    />
                  </td>
                </tr>
              );
            }
          )}

          {createRow('_InstitutionHurdleActive', 'activeHurdle', trancheIndex)}
          {createRow('_SponsorDebt', 'lessSponsorDebt', trancheIndex)}
          {createRow('_HurdleRemaining', 'hurdleRemaining', trancheIndex)}
          {createRow('_GrossUpPercent', 'grossUp', trancheIndex, false, true)}
          {createRow('_OrdinaryEquityHurdle', 'ordinaryEquityHurdle', trancheIndex)}
          {createRow('_IsHurdleMet', 'isHurdleMet', trancheIndex, true)}
          {createRow('_OrdinaryEquityPostHurdle', 'ordinaryEquityPostHurdle', trancheIndex)}
          {createRow('_PcParticipationTitle', 'trancheParticipationPc', trancheIndex, false, true)}
          {createRow('_DistributionsPaid', 'trancheDistribution', trancheIndex)}
          {createRow('_PostIPOLockInDiscount', 'postIpoDlomPc', trancheIndex, false, true)}
          {createRow('_NetProceeds', 'netDistribution', trancheIndex)}
        </React.Fragment>
      ))}
    </>
  );
};

export default SampleWaterfallTranches;
