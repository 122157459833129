import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import styles from './project-menu-item.module.scss';
import { enumKeyByValue } from '@app/shared/helpers';
import { RouteConstants } from '../../RouteConstants';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { useLocale } from '@app/core/hooks/useLocale';

export const PARENT_CLASSNAME = 'menu-item';

interface ProjectMenuItemProps {
  route: string;
  title: string;
}

export const ProjectMenuItem: FC<ProjectMenuItemProps> = ({ route, title }): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const { details: projectDetails } = project;
  const { riskFreeValues: opmRiskFreeResults, realWorldValues: opmRealWorldResults } =
    useAppSelector((state) => state.opmCalculation);
  const {
    isPwermValid,
    isPwermInputsValid,
    isBenchmarkingInputsValid,
    isOpmValid,
    isCapitalStructureValid,
  } = useAppSelector((state) => state.project);
  const {
    pwermErrorMessageList,
    pwermInputsErrorMessageList,
    benchmarkingInputsErrorMessageList,
    opmErrorMessageList,
  } = useAppSelector((state) => state.notification);
  const { l } = useLocale();
  let tooltipPrefix = '';
  let errorList: string[] = [];
  let enableTooltip = false;

  const Link = () => {
    let disabled = false;

    const opmInputRoutes: string[] = [RouteConstants.OpmInputs];
    const opmOutputRoutes: string[] = [
      RouteConstants.DashboardOpm,
      RouteConstants.RealWorldDistributions,
      RouteConstants.OpmWaterfall,
    ];

    const allOpmRoutes = [...opmInputRoutes, ...opmOutputRoutes];

    const legacyPwermInputRoutes: string[] = [RouteConstants.DealThesisLegacy];
    const legacyPwermOutputRoutes: string[] = [
      RouteConstants.PwermWaterfallLegacy,
      RouteConstants.DashboardPwermLegacy,
      RouteConstants.CostOfEquityLegacy,
      RouteConstants.PwermComparator,
      RouteConstants.CostOfEquityComparator,
    ];
    const pwerm2InputRoutes: string[] = [RouteConstants.DealThesis];
    const pwerm2OutputRoutes: string[] = [
      RouteConstants.PwermWaterfall,
      RouteConstants.DashboardPwerm,
      RouteConstants.CostOfEquity,
    ];
    const allPwermRoutes = [
      ...legacyPwermInputRoutes,
      ...legacyPwermOutputRoutes,
      ...pwerm2InputRoutes,
      ...pwerm2OutputRoutes,
    ];

    const valuationInputsRoutes: string[] = [RouteConstants.ValuationInputs];
    const benchmarkingInputsRoutes: string[] = [RouteConstants.BenchmarkingInputs];

    const opmRiskFreeDataExists = Object.keys(opmRiskFreeResults).length > 0;
    const opmRealWorldDataExists = Object.keys(opmRealWorldResults).length > 0;
    const hasValuedInstruments =
      opmRiskFreeResults.valuationConclusion && opmRiskFreeResults.valuationConclusion.length > 0;

    // disable OPM routes in PWERM-only calculation mode
    if (
      (projectDetails.calcMethod === CalcMethod.PWERM || projectDetails.calcMethod === undefined) &&
      allOpmRoutes.includes(route)
    ) {
      disabled = true;
    }

    // disable PWERM routes in OPM-only calculation mode
    if (
      projectDetails.calcMethod === CalcMethod.OPM &&
      (allPwermRoutes.includes(route) || valuationInputsRoutes.includes(route))
    ) {
      disabled = true;
    }

    // disable the OPM dashboards if they have no data
    if (
      projectDetails.calcMethod === CalcMethod.OPM ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      if (route === RouteConstants.DashboardOpm && !opmRiskFreeDataExists) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_RunTheOpmCalculationToEnableThisPage');
      }

      if (route === RouteConstants.RealWorldDistributions && !opmRealWorldDataExists) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_SetTheRealWorldRateAndRunTheOpmCalculationToEnableThisPage');
      }
    }

    // disable the OPM Waterfall if there is no data, or there are no valued instruments
    if (
      projectDetails.calcMethod === CalcMethod.OPM ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      if (route === RouteConstants.OpmWaterfall && !opmRiskFreeDataExists) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_RunTheOpmCalculationToEnableThisPage');
      }

      if (route === RouteConstants.OpmWaterfall && !hasValuedInstruments) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_ThisPageIsDisabledBecauseThereAreNoValuedInstruments');
      }
    }

    // disable PWERM inputs if there are validation errors
    if (pwerm2InputRoutes.includes(route) && !isPwermInputsValid) {
      disabled = true;
      enableTooltip = true;
      tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
      errorList = pwermInputsErrorMessageList ?? [];
    }

    if (opmInputRoutes.includes(route) && !isCapitalStructureValid) {
      disabled = true;
      enableTooltip = true;
      tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
      errorList = opmErrorMessageList ?? [];
    }

    // disable Benchmarking inputs if there are validation errors
    if (benchmarkingInputsRoutes.includes(route) && !isBenchmarkingInputsValid) {
      disabled = true;
      enableTooltip = true;
      tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
      errorList = benchmarkingInputsErrorMessageList ?? [];
    }

    // disable PWERM outputs if there are validation errors
    if (pwerm2OutputRoutes.includes(route) && !isPwermValid) {
      disabled = true;
      enableTooltip = true;
      tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
      errorList = pwermErrorMessageList ?? [];
    }

    // disable OPM outputs if there are validation errors
    if (opmOutputRoutes.includes(route) && !isOpmValid) {
      disabled = true;
      enableTooltip = true;
      tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
      errorList = opmErrorMessageList ?? [];
    }

    // disable PWERM and OPM Overall Conclusion if there is no data
    if (projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)) {
      if (route === RouteConstants.PwermOpmConclusions && !opmRiskFreeDataExists) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_RunTheOpmCalculationToEnableThisPage');
      }
    }

    // disable PWERM and OPM Overall Conclusion if there are validation errors for either calculation
    if (projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)) {
      if (route === RouteConstants.PwermOpmConclusions && (!isPwermValid || !isOpmValid)) {
        disabled = true;
        enableTooltip = true;
        tooltipPrefix = l('_ThisPageIsDisabledDueToTheFollowingErrors');
        const pwermErrors = pwermErrorMessageList ?? [];
        const opmErrors = opmErrorMessageList ?? [];
        const uniqueErrors = new Set([...pwermErrors, ...opmErrors]);
        errorList = [...uniqueErrors];
      }
    }

    const disabledClassName = disabled ? ' disabled' : '';

    const navLink = (
      <span>
        <PwcNavLink
          className={styles[PARENT_CLASSNAME] + disabledClassName}
          route={route}
          disabled={disabled}
          data-testid={route}>
          {title}
        </PwcNavLink>
      </span>
    );

    const generateTooltipContent = (prefixMessage: string, errorList: string[]) => {
      return (
        <>
          <div>{prefixMessage}</div>
          {errorList.length > 0 && (
            <ul className={styles[`${PARENT_CLASSNAME}__error-item`]}>
              {errorList.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </>
      );
    };

    return (
      <>
        {enableTooltip && (
          <Tooltip
            content={<>{generateTooltipContent(tooltipPrefix, errorList)}</>}
            placement={TooltipPlacement.Right}
            useFixedPositioning
            setMinWidthToFitContent>
            {navLink}
          </Tooltip>
        )}
        {!enableTooltip && navLink}
      </>
    );
  };

  return <Link />;
};
