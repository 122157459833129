import { FC } from 'react';
import Button from '../button/Button';
import { ButtonAppearance } from '../button/button-enums';
import styles from './compatibility-level-upgrade.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';

const PARENT_CLASSNAME = 'compatibility-level-upgrade';

interface UpgradeSuccessModalContentProps {
  setShowModal: (showModal: boolean) => void;
}

export const UpgradeSuccessModalContent: FC<UpgradeSuccessModalContentProps> = ({
  setShowModal,
}) => {
  const { l } = useLocale();
  return (
    <>
      <p>{l('_YourProjectHasBeenSuccessfullyUpgradedToTheLatestCompatibilityLevel')}</p>
      <p>{l('_IfYouAreHappyWithTheChangesSaveYourProjectToPersistTheUpgrade')}</p>
      <p>{l('_IfYouWantToReturnToThePreviousCompatibilityLevelReloadTheProject')}</p>
      <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
        <Button
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          data-testid="ok-button"
          onClick={() => {
            setShowModal(false);
          }}>
          {l('_OK')}
        </Button>
      </div>
    </>
  );
};
