import CurrencyLabel from '@app/shared/components/currency-label/CurrencyLabel';
import { FC } from 'react';
import styles from './content-header.module.scss';
import classNames from 'classnames';
import { HeaderVariations } from './types';
import ExpandAllOpm from '@app/shared/components/expand-all/ExpandAllOpm';
import BenchmarkingOnly from '@app/shared/components/benchmarking-only/BenchmarkingOnly';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import HighlightEditableCells from '@app/shared/components/highlight-editable-cells/HighlightEditableCells';
import ShowPerShareFigures from '@app/shared/components/show-per-share-figures/ShowPerShareFigures';
import ExpandAllPwermWaterfallTable from '@app/shared/components/expand-all/ExpandAllPwerm';

interface ContentHeaderProps {
  title?: string | JSX.Element;
  navigation?: JSX.Element;
  actions?: JSX.Element;
  showCurrencyLabel?: boolean;
  subtitle?: string | JSX.Element;
  headerVariation?: HeaderVariations;
  expandAllToggleVisible?: boolean;
  expandAllPwermWaterfallToggleVisible?: boolean;
  benchmarkingOnlyToggleVisible?: boolean;
  highlightEditableCellsToggleVisible?: boolean;
  showPerShareFiguresVisible?: boolean;
}

export const PARENT_CLASSNAME = 'content-header';
export const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  actions,
  navigation,
  headerVariation,
  subtitle,
  showCurrencyLabel = true,
  expandAllToggleVisible = false,
  expandAllPwermWaterfallToggleVisible = false,
  benchmarkingOnlyToggleVisible = false,
  highlightEditableCellsToggleVisible = false,
  showPerShareFiguresVisible = false,
}) => {
  const isAuthenticated = useAppSelector((state) => state.uiValues.isAuthenticated);
  const isSaveButtonVisible = location?.pathname?.startsWith('/projects/') && isAuthenticated;

  return (
    <>
      <div
        className={classNames(styles['content-header__top-section'], {
          [styles[`content-header__top-section--${headerVariation}`]]: headerVariation,
        })}>
        <div className={styles[`${PARENT_CLASSNAME}__info-section`]}>
          <div className={styles[`${PARENT_CLASSNAME}__info-section-inner`]}>
            {subtitle && <h2 className={styles['content-header__sub-title']}>{subtitle}</h2>}
            <h1 className={styles[`${PARENT_CLASSNAME}__title`]}>{title}</h1>
          </div>
          {showCurrencyLabel && <CurrencyLabel />}
          {expandAllToggleVisible && <ExpandAllOpm />}
          {expandAllPwermWaterfallToggleVisible && <ExpandAllPwermWaterfallTable />}
          {highlightEditableCellsToggleVisible && isSaveButtonVisible && <HighlightEditableCells />}
          {showPerShareFiguresVisible && <ShowPerShareFigures />}
          {benchmarkingOnlyToggleVisible && <BenchmarkingOnly />}
        </div>
        <div className={styles[`${PARENT_CLASSNAME}__actions-section`]}>{actions}</div>
      </div>
      {navigation && <div>{navigation}</div>}
    </>
  );
};
