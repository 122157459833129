import api from '@server/api-config';
import env from '@environment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Pwerm2CalculationResultsDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { AppDispatch, RootState } from './store';
import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';
import { Pwerm2InputCalculationResultsDto } from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import { EndpointConstants } from './EndpointConstants';

export interface Pwerm2CalculationState {
  projectId?: number;
  calculatedResults: Pwerm2CalculationResultsDto;
  calculatedInputs: Pwerm2InputCalculationResultsDto;
}

export const pwerm2CalculateInputs = createAsyncThunk<
  { calculations: Pwerm2InputCalculationResultsDto; projectId: number },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculatePwerm2Inputs', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;
  const apiURL = `${env.apiUrl}${EndpointConstants.CalculatePwermInputs}`;
  const results = await api.post<WithValidationResult<Pwerm2InputCalculationResultsDto>>(
    apiURL,
    JSON.stringify(project)
  );

  return {
    calculations: results.data.result,
    projectId: project.id,
  };
});

export const pwerm2Calculate = createAsyncThunk<
  { calculations: Pwerm2CalculationResultsDto; projectId: number },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculatePwerm2', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;

  const results = await api.post<WithValidationResult<Pwerm2CalculationResultsDto>>(
    `${env.apiUrl}${EndpointConstants.CalculatePwerm}`,
    JSON.stringify(project)
  );
  return { calculations: results.data.result, projectId: project.id };
});

export const pwerm2CalculationSlice = createSlice({
  name: 'pwerm2calculation',
  initialState: { calculatedResults: {}, calculatedInputs: {} } as Pwerm2CalculationState,
  reducers: {
    clearPwerm2Results: (state) => {
      state.calculatedResults = {} as Pwerm2CalculationResultsDto;
      state.calculatedInputs = {} as Pwerm2InputCalculationResultsDto;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pwerm2Calculate.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      state.calculatedResults = {
        ...action.payload.calculations,
      };
    });
    builder.addCase(pwerm2CalculateInputs.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      state.calculatedInputs = {
        ...action.payload.calculations,
      };
    });
  },
});

export const { clearPwerm2Results } = pwerm2CalculationSlice.actions;

export default pwerm2CalculationSlice.reducer;
