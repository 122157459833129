import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SvgChevron from '@app/shared/icons/Chevron';
import { Fragment } from 'react';
import styles from '../../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { OpmInputFormModel } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useLocale } from '@app/core/hooks/useLocale';
import { SummaryShareholderDebt } from './components/SummaryShareholderDebt';
import { getEquityInstrumentOwnerships } from '@app/shared/helpers/equity-instruments/get-equity-instrument-ownerships';
import { CalcMethod, EventKey, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import {
  InstrumentDefinitionAndInitialValues,
  selectCapitalStructure,
} from '@app/core/store/capital-structure-slice-selectors';
import classNames from 'classnames';

export const ShareholderDebtSection = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const opmInput = { ...project.opmInput };
  const selectedEventSetId = opmInput.selectedEventSetId ?? EventKey.EmptyEventSet;
  const capitalStructureResults = useAppSelector((state) => state.capitalStructure.values);
  const debtInstrumentResults =
    capitalStructureResults.debtInstrumentsByYear.eventSets[selectedEventSetId];
  const { control } = useFormContext<OpmInputFormModel>();
  const { fields } = useFieldArray({
    name: 'slnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (opmInput?.perYearInputs.length ?? 0) + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const [expanded, setExpanded] = useLocalStorageCollapseState(
    'expanded-shareholder-debt',
    project.id
  );

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;

  const showShareholderDebtSection = fields.length > 0;

  return showShareholderDebtSection && debtInstrumentResults ? (
    <>
      <tbody>
        <tr data-testid="sd-title-section">
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_ShareholderDebtTitle'),
                })}
                aria-expanded={expanded}
                style={{ border: 'none', backgroundColor: 'none', background: 'none' }}
                onClick={toggleCollapse}
                data-testid="sd-collapse-button">
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_ShareholderDebtTitle')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="sd-badge">
                <Pill size={PillSize.Xsmall} noTextTransform theme={PillTheme.White}>
                  {l(`_Synced`)}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            <SummaryShareholderDebt />
            {fields.length > 0 && (
              <tr className="table-primary__row--plain">
                <td colSpan={sectionColCount} className="table-primary__cell--header">
                  {l('_ShareholderDebtInstruments')}
                </td>
              </tr>
            )}
            {fields.map((instrument: InstrumentDefinitionAndInitialValues) => {
              const isInstrumentPresentInInitialStructure =
                instrument.instrumentId in capitalStructure.initialValues;
              const allOwnersForInstrument = Object.values(debtInstrumentResults.years)
                .flatMap((i) =>
                  Object.entries(i.instruments)
                    .filter(([instrumentId, _]) => instrumentId === instrument.instrumentId)
                    .map(([_, instrumentValue]) => instrumentValue)
                )
                .flatMap((i) => Object.keys(i.perOwner));
              const uniqueOwnersForInstrument = [...new Set(allOwnersForInstrument)];
              const initialOwnership = getEquityInstrumentOwnerships(instrument, (x) => x.amount);
              if (uniqueOwnersForInstrument.length === 0) {
                return <Fragment key={instrument.instrumentId} />;
              }
              return (
                <Fragment key={instrument.instrumentId}>
                  <tr className="table-primary__row--header">
                    <th
                      colSpan={sectionColCount}
                      className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                      {instrument.instrumentNarrative}
                    </th>
                  </tr>
                  {uniqueOwnersForInstrument.map((owner, index) => {
                    const initialValue =
                      owner in initialOwnership
                        ? initialOwnership[owner as keyof typeof OwnerType]
                        : null;
                    return (
                      <Fragment key={index}>
                        <tr className="table-primary__row--secondary">
                          <th
                            colSpan={
                              (project.opmInput.forecastYears ?? 0) +
                              OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
                            }
                            className="table-primary__cell--indentation table-primary__cell--strong">
                            {OwnerType[owner as keyof typeof OwnerType]}
                          </th>
                        </tr>
                        <tr>
                          <th className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                            {l('_ClosingBalance')}
                          </th>
                          <td
                            className={classNames({
                              [styles['non-existent-instrument-value']]:
                                !isInstrumentPresentInInitialStructure,
                            })}>
                            {initialValue === null ? (
                              <></>
                            ) : (
                              <CellValue {...amountRules} value={initialValue} />
                            )}
                          </td>
                          {project.opmInput.perYearInputs?.map((forecast, index) => {
                            const instrumentInYear =
                              instrument.instrumentId in
                              (debtInstrumentResults.years[forecast.forecastDate ?? '']
                                ?.instruments ?? {});
                            const ownership = instrumentInYear
                              ? debtInstrumentResults.years[forecast.forecastDate ?? '']
                                  ?.instruments[instrument.instrumentId].perOwner
                              : null;
                            const closingBalance =
                              instrumentInYear && ownership && owner in ownership
                                ? ownership[owner as keyof typeof OwnerType]
                                : null;
                            return (
                              <td
                                key={index}
                                className={classNames({
                                  [styles['non-existent-instrument-value']]: !instrumentInYear,
                                })}>
                                {closingBalance === null ? (
                                  <></>
                                ) : (
                                  <CellValue
                                    dataTestId={slugifyStrings(
                                      instrument.instrumentNarrative,
                                      owner,
                                      'closing-balance-cell-value',
                                      forecast.forecastDate ?? ''
                                    )}
                                    {...amountRules}
                                    value={closingBalance}
                                  />
                                )}
                              </td>
                            );
                          })}
                          <td />
                        </tr>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </>
        )}
      </tbody>
    </>
  ) : (
    <></>
  );
};

export default ShareholderDebtSection;
