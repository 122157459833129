import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  setAllPwermWaterfallTableCollapseState,
  setExpandAllPwermWaterfallState,
} from '@app/core/store/ui-values-slice';
import styles from './expandall.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { useLocale } from '@app/core/hooks/useLocale';
import { Toggle, ToggleSize } from '../toggle/Toggle';

const ExpandAllPwermWaterfallTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const expandAll = useAppSelector((state) => state.uiValues.expandAllPwermWaterfall);
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );

  const forcedChecked = Object.values(pwermWaterfallTableStates).every((val) => val === true);

  useEffect(() => {
    dispatch(setExpandAllPwermWaterfallState(forcedChecked));
  }, [dispatch, forcedChecked]);

  const handleChange = () => {
    const newExpandAllState = !expandAll;
    dispatch(setExpandAllPwermWaterfallState(newExpandAllState));
    dispatch(setAllPwermWaterfallTableCollapseState(newExpandAllState));
  };

  const formMethods = useForm({
    ...formConfigBase,
  });

  return (
    <FormProvider {...formMethods}>
      <Toggle
        name="expandAll"
        label={l('_ExpandAllRows')}
        toggleSize={ToggleSize.Medium}
        className={styles['expand-all']}
        forceChecked={forcedChecked}
        onChange={handleChange}
        ignoreReadOnly
        data-testid="expand-all-toggle"
      />
    </FormProvider>
  );
};

export default ExpandAllPwermWaterfallTable;
