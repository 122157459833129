export const calculateYearFrac365 = (startDate: Date, endDate: Date) => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startFraction = getDayOfYear(startDate) / 365;
  const endFraction = getDayOfYear(endDate) / 365;

  const yearFraction = endYear + endFraction - (startYear + startFraction);

  return yearFraction;
};

const getDayOfYear = (date: Date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 0);
  const diff = date.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};
