import { FC, useState } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import * as projectActions from '@core/store/project-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { HotkeysProvider, useHotkeys } from 'react-hotkeys-hook';
import { useLocale } from '@app/core/hooks/useLocale';

export const SaveProjectAction: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const isSaving = useAppSelector((state) => state.uiValues.operationStatus.isSaving);
  const [isOpen, setIsOpen] = useState(false);
  const saveProject = () => {
    dispatch(projectActions.saveExistingProject());
    setIsOpen(false);
  };
  const validateProject = () => {
    // "Validate" call has been removed because it wasn't doing anything useful, the actual save action includes validation anyway, so just skip to opening the confirmation dialog.
    setIsOpen(true);
  };

  const handleSave = () => {
    if (!isSaving) {
      project.status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed)
        ? validateProject()
        : saveProject();
    }
  };

  useHotkeys(
    'mod+s',
    (event) => {
      event.preventDefault();
      handleSave();
    },
    { enableOnFormTags: ['input', 'select', 'textarea'] }
  );

  const { l } = useLocale();

  return (
    <>
      <HotkeysProvider>
        <Button
          data-testid="save-project-main"
          onClick={() => handleSave()}
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          size={ButtonSize.SMALL}>
          {l(`_Save`)}
        </Button>
        <BaseModal
          size={ModalSize.Small}
          onCloseButtonClick={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
          onConfirm={saveProject}
          title={l(`_SaveClosedConfirmationDialogTitle`)}
          isOpen={isOpen}
          buttonCancelLabel={l(`_DontClose`)}
          buttonConfirmLabel={l(`_ConfirmAndCloseProject`)}>
          {l(`_SaveClosedConfirmationDialogBody`)}
        </BaseModal>
      </HotkeysProvider>
    </>
  );
};
