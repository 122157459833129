import { Outlet, useNavigate } from 'react-router-dom';
import { SnackbarCloseReason } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import Snackbar from '@app/shared/mui-components/snackbar/Snackbar';
import { SyntheticEvent, useEffect } from 'react';
import * as notificationActions from '@app/core/store/notification-slice';
import { CloseReason } from '@app/shared/mui-components/snackbar/snackbar-types';
import { MainLayout } from '@app/shared/layout/main-layout/MainLayout';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { CardVariation } from '@app/shared/components/card/card-enums';
import useBeforeunload from '@core/hooks/customUseBeforeUnload';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import ReactRouterPrompt from '@app/shared/components/react-route-blocker/ReactRouteBlocker';
import { userHasUnsavedChanges } from '@core/store/project-slice-selectors';
import * as projectActions from '@core/store/project-slice';
import ErrorHandlingModal from '@app/shared/components/status-pages/ErrorHandlingModal';
import { loadAppInsights } from '@server/load-app-insights';
import store from '@core/store/store';
import * as userInfoActions from '@core/store/user-info.slice';
import * as uiValuesActions from '@core/store/ui-values-slice';
import { i18nSetup } from '../locale/setupI18n';
import SvgXMark from './shared/icons/XMark';
import { useLocale } from './core/hooks/useLocale';
import { DatadogClient } from '@server/DatadogClient';
import { selectMaintenanceModeSettings } from './core/store/ui-values-slice-selectors';
import UnderMaintenancePage from './modules/projects/maintenance/UnderMaintenancePage';
import { RootAppRoutes } from './shared/enums/root-app-routes';
import { setupMaintenanceModeBinding } from '@server/maintenance-mode';

i18nSetup();

const App = () => {
  const notificationState = useAppSelector((state) => state.notification);
  const isAuthenticated = useAppSelector((state) => state.uiValues.isAuthenticated);
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const hasUnsavedChanges = useAppSelector(userHasUnsavedChanges);
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const navigate = useNavigate();
  const maintenanceModeSettings = useAppSelector(selectMaintenanceModeSettings);
  setupMaintenanceModeBinding();

  // We need to loadAppInsights after registerApiInterceptor due to
  // possibility of 500 error in /configuration endpoint
  // which then would be handled by interceptor
  useEffect(() => {
    const loadAsync = async () => {
      await store.dispatch(uiValuesActions.fetchApplicationConfiguration());
      loadAppInsights();
      store.dispatch(userInfoActions.fetchUserInfo());
    };

    loadAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userInfo.email !== '') {
      DatadogClient.initialize();
    }
  }, [userInfo]);

  useBeforeunload((event: Event) => {
    event.preventDefault();
  });

  const handleNotificationClose = (_: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === CloseReason.clickAway) {
      return;
    }

    dispatch(notificationActions.closeNotification());
  };

  useEffect(() => {
    if (maintenanceModeSettings.active) {
      navigate(RootAppRoutes.Maintenance);
    }
  }, [maintenanceModeSettings.active, navigate]);

  if (maintenanceModeSettings.active) {
    return <UnderMaintenancePage />;
  }

  return (
    <>
      {isAuthenticated && (
        <>
          <ErrorHandlingModal />
          <ReactRouterPrompt when={hasUnsavedChanges}>
            {({ isActive, onCancel, onConfirm, location }) => {
              return (
                <BaseModal
                  size={ModalSize.Small}
                  title="Unsaved changes"
                  buttonConfirmLabel="Continue"
                  buttonCancelLabel="Cancel"
                  blockedUrl={location?.pathname}
                  onCloseButtonClick={onCancel}
                  onConfirm={() => {
                    dispatch(projectActions.discardChanges());
                    onConfirm();
                  }}
                  onCancel={onCancel}
                  isOpen={isActive}>
                  {l('_UnsavedChangesWarning')}
                </BaseModal>
              );
            }}
          </ReactRouterPrompt>
          <Snackbar
            dontHide={!notificationState.notification?.autoHide}
            open={notificationState.isSnackBarVisible}
            data-testid="notification-success"
            onClose={handleNotificationClose}>
            <div>
              <NotificationMessage
                type={notificationState.notification?.severity}
                optionalSpacing={CardVariation.TertiarySpacing}>
                {notificationState.notification?.message}
                <Button
                  appearance={ButtonAppearance.CLEAN}
                  startIcon={notificationState.notification?.autoHide ? undefined : <SvgXMark />}
                  onClick={
                    notificationState.notification?.autoHide
                      ? undefined
                      : () => dispatch(notificationActions.closeNotification())
                  }
                />
              </NotificationMessage>
            </div>
          </Snackbar>
        </>
      )}

      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  );
};

export default App;
