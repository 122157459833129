import classNames from 'classnames';
import { FC } from 'react';
import styles from '../../widgets/total-equity/total-equity-chart.module.scss';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { Pwerm2ForecastTooltip } from './Pwerm2ForecastTooltip';
import { Pwerm2CalculationDashboardTotalEquityChartYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';

const PARENT_CLASSNAME = 'total-equity-chart';

interface InstrumentColumnProps {
  instrumentBlocks: JSX.Element[];
  isExpanded: boolean;
  isEntryColumn?: boolean;
  isLastForecast?: boolean;
  forecastData: Pwerm2CalculationDashboardTotalEquityChartYearDto;
  heightPct: number;
  onClick: () => void;
}

export const Pwerm2InstrumentColumn: FC<InstrumentColumnProps> = ({
  instrumentBlocks,
  isExpanded,
  isEntryColumn = false,
  isLastForecast = false,
  forecastData,
  heightPct,
  onClick,
}) => {
  const tooltipPlacement = isEntryColumn
    ? TooltipPlacement.Right
    : isLastForecast
    ? TooltipPlacement.Left
    : TooltipPlacement.Top;

  return (
    <Tooltip
      placement={tooltipPlacement}
      className={classNames(
        styles[`${PARENT_CLASSNAME}__column`],
        styles[`${PARENT_CLASSNAME}__forecast-tooltip`],
        {
          [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
        }
      )}
      style={{
        height: `${heightPct}%`,
      }}
      content={
        <Pwerm2ForecastTooltip forecastData={forecastData} isEntryTooltip={isEntryColumn} />
      }>
      <div style={{ height: '100%' }} onClick={onClick}>
        {instrumentBlocks}
      </div>
    </Tooltip>
  );
};
