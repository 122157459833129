import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { instrumentsSortFn } from '@app/shared/helpers';
import NoResults from '@app/shared/components/no-results/NoResults';
import { Card } from '@app/shared/components/card/Card';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { SecondaryNavigationVariation } from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { RouteConstants } from '../../../../RouteConstants';
import {
  setCapitalStructurePayoutLogicSelectedSubTab,
  setCapitalStructureSelectedTab,
} from '@app/core/store/ui-values-slice';
import { CardVariation } from '@app/shared/components/card/card-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import { selectOrdinaryEquityInstruments } from '@app/core/store/capital-structure-slice-selectors';

interface PayoutLogicMainProps {
  children: ReactNode;
}

export const PayoutLogicMainErf: FC<PayoutLogicMainProps> = ({ children }): JSX.Element => {
  const { instrumentId } = useParams();
  const dispatch = useAppDispatch();
  useSetSelectedTab(RouteConstants.PayoutLogic, setCapitalStructureSelectedTab);
  const { l } = useLocale();

  const instrumentIdRef = useRef(instrumentId);

  useEffect(() => {
    instrumentIdRef.current = instrumentId;
  }, [instrumentId]);

  useEffect(() => {
    return () => {
      // as this file sets the instrument, instrumentIdRef will be undefined on the first render
      if (instrumentIdRef.current !== undefined) {
        dispatch(setCapitalStructurePayoutLogicSelectedSubTab(instrumentIdRef.current));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storedPayoutLogicSubTab = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.payoutLogic.selectedSubTab
  );

  const sortedOrdinaryEquityItems = useAppSelector(selectOrdinaryEquityInstruments).sort(
    instrumentsSortFn
  );

  const isStoredPayoutLogicSubTabValid = Boolean(
    sortedOrdinaryEquityItems.find((item) => item.instrumentId === storedPayoutLogicSubTab)
  );

  const navigationItems: NavigationItem[] =
    sortedOrdinaryEquityItems.map((item) => ({
      label: item.instrumentNarrative || EmptyValues.EnDash,
      route: ':instrumentId',
      params: { instrumentId: (item.instrumentId || 0).toString() },
    })) || [];

  if (storedPayoutLogicSubTab && isStoredPayoutLogicSubTabValid && !instrumentId) {
    return <PwcNavigate route=":instrumentId" params={{ instrumentId: storedPayoutLogicSubTab }} />;
  } else if (
    !instrumentId ||
    (Number(instrumentId) !== 0 &&
      !sortedOrdinaryEquityItems.find((item) => item.instrumentId === instrumentId))
  ) {
    return (
      <PwcNavigate
        route=":instrumentId"
        params={{
          instrumentId: (sortedOrdinaryEquityItems[0]
            ? sortedOrdinaryEquityItems[0].instrumentId
            : 0
          ).toString(),
        }}
      />
    );
  }

  return (
    <>
      {sortedOrdinaryEquityItems.length ? (
        <>
          <Card variation={CardVariation.PayoutLogicSubTabs}>
            <SecondaryNavigation
              navigationItems={navigationItems}
              variation={SecondaryNavigationVariation.PayoutLogicSubTabs}
            />
          </Card>
          <Card>{children}</Card>
        </>
      ) : (
        <NoResults message={l('_AddOrdinaryEquityInstrumentsToAddPayoutLogic')} />
      )}
    </>
  );
};
