import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import {
  WaterfallSections,
  WaterfallSectionsErf,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { useLocale } from '@app/core/hooks/useLocale';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import {
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import useTogglerPwermWaterfall from '@app/core/hooks/useTogglerPwermWaterfall';

export const Pwerm2WaterfallTotalEquityProceedsPartialTable: FC<
  Pwerm2WaterfallPartialTableProps
> = ({ columnsCount }): JSX.Element => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );
  const { handleToggle } = useTogglerPwermWaterfall(WaterfallSectionsErf.TotalEquityProceeds);

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_TotalEquityProceeds')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.TotalEquityProceeds)}
          isOpen={pwermWaterfallTableStates[WaterfallSectionsErf.TotalEquityProceeds]}
          setIsOpen={handleToggle}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed':
            !pwermWaterfallTableStates[WaterfallSectionsErf.TotalEquityProceeds],
        })}>
        <TableSectionValuesPerForecast
          title={l('_NetDebtNegativeOrNetCash')}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.totalNetCash
              )}
            />
          )}
        />
        <TableSectionValuesPerMultiple
          title={l('_TotalEquity')}
          testId="totalEquity"
          renderCell={({ caseItem, forecast, multipleId }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearMultipleValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                multipleId!,
                (multiple) => multiple.totalEquity
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_WeightedTotalEquity')}
          highlight
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.weightedTotalEquityProceeds
              )}
            />
          )}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
      </tbody>
    </>
  );
};
