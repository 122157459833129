import { FC, useState } from 'react';
import { ModalSize } from '../modal/base-modal-enums';
import BaseModal from '../modal/BaseModal';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectLatestProjectCompatibilityLevel } from '@app/core/store/ui-values-slice-selectors';
import * as projectActions from '@core/store/project-slice';
import { useLocale } from '@app/core/hooks/useLocale';
import { UpgradeSuccessModalContent } from './UpgradeSuccessModalContent';
import { UpgradeDetailsModalContent } from './UpgradeDetailsModalContent';
import { SpinnerLoader } from '@app/shared/components/loader/SpinnerLoader';
import { LoaderSize } from '../loader/loader-enums';
import styles from './compatibility-level-upgrade.module.scss';

const PARENT_CLASSNAME = 'compatibility-level-upgrade';

interface CompatibilityLevelUpgradeModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export const CompatibilityLevelUpgradeModal: FC<CompatibilityLevelUpgradeModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);

  const latestLevel = useAppSelector(selectLatestProjectCompatibilityLevel)!;

  const triggerUpgrade = async () => {
    setIsUpgrading(true);
    await dispatch(projectActions.upgradeProject(latestLevel.compatibilityLevel));
    setIsUpgrading(false);
    setShowSuccessMessage(true);
  };

  return (
    <BaseModal
      title={showSuccessMessage ? l('_ProjectUpgradedSuccessfully') : l('_UpgradeProject')}
      size={ModalSize.Medium}
      isOpen={showModal}
      onCloseButtonClick={() => setShowModal(false)}>
      {showSuccessMessage ? (
        <UpgradeSuccessModalContent setShowModal={setShowModal} />
      ) : isUpgrading ? (
        <div>
          <div className={styles[`${PARENT_CLASSNAME}__upgrade-message`]}>
            {l('_UpgradingProject')}
          </div>
          <div className={styles[`${PARENT_CLASSNAME}__spinner`]}>
            <SpinnerLoader size={LoaderSize.Large} />
          </div>
        </div>
      ) : (
        <UpgradeDetailsModalContent triggerUpgrade={triggerUpgrade} setShowModal={setShowModal} />
      )}
    </BaseModal>
  );
};
