import envConfig from './environment-config';

export enum Environment {
  Local = 'local',
  Development = 'dev',
  Test = 'test',
  E2E = 'e2e',
  UAT = 'uat',
  Staging = 'staging',
  Production = 'production',
  Dem = 'dem',
}

export default { ...envConfig[window._env.current], ...window._env };
