import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { isInstrumentOwnerAmountEntered } from '@app/shared/helpers';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis-2/DealThesisPage2';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getDealThesisInputCaseForecastDateShareholderDebtInstrumentsOwner,
  getDealThesisInputCaseValuationDateShareholderDebtInstrumentsOwner,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import { selectSlnAndPrefSharesInstrumentsWithInitialValues } from '@app/core/store/capital-structure-slice-selectors';

interface DealThesisCaseSlnAndPrefShareSectionProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseSlnAndPrefShareSection2: FC<
  DealThesisCaseSlnAndPrefShareSectionProps
> = () => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!].forecastDates).length;
  const shareholderInstruments = results.cases[caseId!].valuationDate.shareholderDebtInstruments;
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  const historicData = project.pwermInput.historicForecasts;
  const numberOfInputHistoricForecastYears = historicData.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicResultsData = Object.values(
    results.cases[caseId!].historicDates.historicForecastYears ?? {}
  )
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const numberOfResultsHistoricForecastYears = historicResultsData.length;
  const slnAndPrefSharesInstruments = useAppSelector(
    selectSlnAndPrefSharesInstrumentsWithInitialValues
  );
  const { l } = useLocale();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  const { control } = useFormContext<DealThesisFormModel>();
  const { fields } = useFieldArray({
    name: 'slnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (numberOfResultsForecastYears ?? 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    numberOfResultsHistoricForecastYears;

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    (ins) => ins.initialValues && isInstrumentOwnerAmountEntered(ins.initialValues)
  );

  return !filteredSlnAndPrefSharesInstruments.length ? (
    <></>
  ) : (
    <>
      <tbody>
        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--header">{l('_ShareholderDebtInstruments')}</th>
          <td className="table-primary__cell--header" colSpan={sectionColCount - 1} />
        </tr>
        {fields.map((instrument) => {
          return (
            <Fragment key={instrument.id}>
              <tr className="table-primary__row--header">
                <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                  {instrument.instrumentNarrative}
                </th>
                <td
                  className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
                  colSpan={sectionColCount - 1}
                />
              </tr>
              {Object.keys(
                shareholderInstruments[instrument.instrumentId].closingBalanceByOwner
              ).map((owner, index) => {
                return (
                  <Fragment key={index}>
                    <tr className="table-primary__row--secondary">
                      <th className="table-primary__cell--indentation table-primary__cell--strong">
                        {OwnerType[owner as keyof typeof OwnerType]}
                      </th>
                      <td
                        colSpan={
                          (numberOfResultsForecastYears ?? 0) +
                          DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
                          numberOfResultsHistoricForecastYears
                        }
                        className="table-primary__cell--indentation table-primary__cell--strong"
                      />
                    </tr>
                    <tr>
                      <th className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                        {l('_ClosingBalance')}
                      </th>
                      {showHistoricDataColumns &&
                        historicResultsData.map((_, index) => <td key={index} />)}
                      <td>
                        <CellValue
                          {...amountRules}
                          value={getDealThesisInputCaseValuationDateShareholderDebtInstrumentsOwner(
                            results,
                            caseId!,
                            instrument.instrumentId,
                            owner as OwnerType
                          )}
                        />
                      </td>
                      {caseForecasts?.map((forecast, index) => (
                        <td key={index}>
                          <CellValue
                            {...amountRules}
                            value={getDealThesisInputCaseForecastDateShareholderDebtInstrumentsOwner(
                              results,
                              caseId!,
                              forecast.forecastYear,
                              instrument.instrumentId,
                              owner as OwnerType
                            )}
                          />
                        </td>
                      ))}
                      <td />
                    </tr>
                  </Fragment>
                );
              })}
              <DealThesisSpacerRow2 />
            </Fragment>
          );
        })}
      </tbody>
    </>
  );
};
