import { FC, Fragment, useState } from 'react';
import styles from '../../widgets/total-equity/total-equity-chart.module.scss';
import classNames from 'classnames';
import { enumKeyByValue, groupBy } from '@app/shared/helpers';
import {
  CapitalStructure,
  Instrument,
} from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { abbreviatedValueFormatter, amountValueFormatterProps } from '@app/shared/formatters';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { calculateCapitalStructureDimensions } from '@app/modules/projects/inputs/capital-structure/capital-structure-block-size-calculator';
import {
  MAX_COLLAPSED_ITEMS,
  MAX_LABEL_ITEMS,
} from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import SvgPlus from '@app/shared/icons/Plus';
import MinusIcon from '@app/shared/icons/MinusIcon';
import { labelTopValue } from '@app/shared/helpers/labeling-helpers';
import {
  DenominationMultiplier,
  InstrumentType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import {
  Pwerm2CalculationDashboardTotalEquityChartYearDto,
  Pwerm2CalculationDashboardTotalEquityChartYearRankDto,
} from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import Pwerm2InstrumentBlockComponent from './Pwerm2InstrumentBlock';
import Pwerm2NetDebtColumn from './Pwerm2NetDebtColumn';
import { useLocale } from '@app/core/hooks/useLocale';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import {
  InstrumentDefinitionWithId,
  selectCapitalStructure,
  selectInstruments,
} from '@app/core/store/capital-structure-slice-selectors';
import { Pwerm2InstrumentColumn } from './Pwerm2InstrumentColumn';
import { Pwerm2ForecastTooltip } from './Pwerm2ForecastTooltip';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { PwermTotalEquityModal } from './total-equity-modal/Pwerm2TotalEquityModal';

const PARENT_CLASSNAME = 'total-equity-chart';

interface TotalEquityChartProps {
  isExpanded: boolean;
  filteredCase?: CaseDto;
  forecastData: ForecastDto[];
  setIsExpanded: (isExpanded: boolean) => void;
}

const Pwerm2TotalEquityChartPwerm: FC<TotalEquityChartProps> = ({
  isExpanded,
  setIsExpanded,
  forecastData,
  filteredCase,
}): JSX.Element => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isTotalEquityChartWithModalEnabled = isDevFeatureEnabled(
    DevFeature.TotalEquityChartWithModal
  );
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const investmentDate = useAppSelector((state) => state.project.projectDraft.investmentDate);
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.totalEquity
  );
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseItemWithMaxForecasts = getCaseWithMostForecasts(
    getCasesWithNonZeroProbability(project.pwermInput.cases)
  );
  const netDebtRank = -1;
  const capitalStructure = useAppSelector(selectCapitalStructure);
  const instrumentDefinitions = useAppSelector(selectInstruments);

  const [isTotalEquityChartModalVisible, setIsTotalEquityChartModalVisible] = useState(false);
  const [selectedStructure, setSelectedStructure] =
    useState<Pwerm2CalculationDashboardTotalEquityChartYearDto | null>(null);

  const getSelectedStructure = (isEntry: boolean, forecastIndex?: number) => {
    if (isEntry) {
      return filteredCase
        ? results.cases[filteredCase.caseId].chart.entry
        : results.weighted.chart.entry;
    } else if (forecastIndex !== undefined && forecastIndex !== null) {
      const yearsArray = filteredCase
        ? Object.values(results.cases[filteredCase.caseId].chart.years)
        : Object.values(results.weighted.chart.years);
      return yearsArray[forecastIndex];
    }
    return null;
  };

  const chartForecastsCount = isExpanded
    ? forecastData.length
    : forecastData.length > MAX_COLLAPSED_ITEMS
    ? MAX_COLLAPSED_ITEMS
    : forecastData.length;

  const findNetDebtRank = (
    ranks: Pwerm2CalculationDashboardTotalEquityChartYearRankDto[]
  ): number => {
    const foundNetDebtRank = ranks.find((rank) => rank.rank === netDebtRank);
    return foundNetDebtRank ? foundNetDebtRank.totalProceeds : 0;
  };

  const getCaseOrProjectNetDebt = (forecastCount: number): number => {
    const yearsArray = filteredCase
      ? Object.values(results.cases[filteredCase.caseId].chart.years)
      : Object.values(results.weighted.chart.years);

    const ranks = yearsArray[forecastCount]?.ranks;
    return ranks ? findNetDebtRank(ranks) : 0;
  };

  const createStructureForInstruments = <T extends Instrument>(instruments: T[]) =>
    calculateCapitalStructureDimensions(instruments, 100, 100, 0, 0, false);

  const getCaseWeightedStructure = (
    ranks: Pwerm2CalculationDashboardTotalEquityChartYearRankDto[]
  ) => {
    const distributions = ranks.map((rank) => {
      return rank.instruments.map((instrument) => {
        return {
          instrumentId: instrument.id,
          value: instrument.totalProceeds,
          type: instrument.type,
          rank: rank.rank,
          proportionOfType: instrument.proportionOfType,
          proceedsByOwner: instrument.proceedsByOwner,
        };
      });
    });

    const distributionsByInstrument = groupBy(distributions.flat(), (x) => x.instrumentId);

    // removing net debt
    Object.keys(distributionsByInstrument).forEach((key) => {
      const instrument = distributionsByInstrument[key];
      const hasNetDebt = instrument.some((x) => x.rank === netDebtRank);

      if (hasNetDebt) {
        delete distributionsByInstrument[key];
      }
    });

    return createStructureForInstruments(
      Object.values(distributionsByInstrument).map((ins) => {
        const definition = instrumentDefinitions.find(
          (item) => item.instrumentId === ins[0].instrumentId
        );
        return {
          ...definition!,
          amount: ins[0].value,
          proportionOfType: ins[0].proportionOfType,
          proceedsByOwner: ins[0].proceedsByOwner,
        };
      })
    );
  };

  const renderRanks = (
    structure: CapitalStructure<InstrumentDefinitionWithId>,
    forecastIndex?: number
  ) => {
    return structure.ranks.map((rank, index) => {
      return (
        <Fragment key={`instrument-${index}`}>
          <div
            key={'rank-block-' + index}
            style={{ height: rank.height + '%' }}
            className={classNames(styles['graph__rank-block'], {
              [styles['graph__rank-block--expanded']]: isExpanded,
            })}>
            {rank.instrumentBlocks.map((instrument, index) => {
              return (
                <Pwerm2InstrumentBlockComponent
                  key={'instrument-block-component' + index + instrument.instrumentId}
                  forecastIndex={forecastIndex}
                  instrument={instrument}
                  isPreModalChart
                />
              );
            })}
          </div>
        </Fragment>
      );
    });
  };

  const totalInvestments = filteredCase
    ? results.cases[filteredCase.caseId].entryTotalEquity
    : results.weighted.entryTotalEquity;

  const entryResults = filteredCase
    ? results.cases[filteredCase.caseId].chart.entry
    : results.weighted.chart.entry;

  const forecastResults = forecastData.map((f) =>
    filteredCase
      ? results.cases[filteredCase.caseId].chart.years[f.forecastYear]
      : results.weighted.chart.years[f.forecastYear]
  );

  const stacksPerForecast = forecastResults.map((f) => getCaseWeightedStructure(f.ranks));

  const entryStackStructure = getCaseWeightedStructure(entryResults.ranks);

  const totalEquityPerForecast = filteredCase
    ? Object.values(results.cases[filteredCase.caseId].chart.years).map((x) => x.totalProceeds)
    : Object.values(results.weighted.chart.years).map((x) => x.totalProceeds);

  const getInitialNetDebtValue = () => {
    const ranks = filteredCase
      ? results.cases[filteredCase.caseId].chart.entry.ranks
      : results.weighted.chart.entry.ranks;

    return ranks ? findNetDebtRank(ranks) : 0;
  };

  const getMaxDebtValue = () => {
    const getMaxValue = (caseItem: CaseDto) =>
      Math.max(...caseItem.forecasts.map((_, i) => Number(getCaseOrProjectNetDebt(i))));

    if (filteredCase) {
      return getMaxValue(filteredCase);
    }

    return getMaxValue(caseItemWithMaxForecasts);
  };

  const maxEquity = Math.max(totalInvestments, ...totalEquityPerForecast);

  const calculatedLabelTopValue = labelTopValue(maxEquity);

  const getMaxNetDebtAxisCount = () => {
    // eslint-disable-next-line big-number-rules/rounding
    const maxNetDebtLabels = Math.ceil(
      Math.max(getMaxDebtValue(), getInitialNetDebtValue()) /
        (calculatedLabelTopValue / MAX_LABEL_ITEMS)
    );
    return maxNetDebtLabels > MAX_LABEL_ITEMS ? MAX_LABEL_ITEMS : maxNetDebtLabels;
  };

  const shlInstrumentsExist = Object.values(capitalStructure.instrumentDefinitions).some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );

  const prefShareInstrumentsExist = Object.values(capitalStructure.instrumentDefinitions).some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const ordEquityInstrumentsExist = Object.values(capitalStructure.instrumentDefinitions).some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && !i.isSweetEquity
  );

  const sweetEquityInstrumentsExist = Object.values(capitalStructure.instrumentDefinitions).some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && i.isSweetEquity
  );

  return (
    <>
      <div className={styles[PARENT_CLASSNAME]}>
        <div className={styles[`${PARENT_CLASSNAME}__legend`]}>
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--net-debt`],
              ])}
            />
            {l('_NetDebt')}
          </Button>
          {shlInstrumentsExist && (
            <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
              <span
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__icon`],
                  styles[`${PARENT_CLASSNAME}__icon--sln`],
                ])}
              />
              {InstrumentType.ShareholderLoanNotes}
            </Button>
          )}
          {prefShareInstrumentsExist && (
            <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
              <span
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__icon`],
                  styles[`${PARENT_CLASSNAME}__icon--pref`],
                ])}
              />
              {InstrumentType.PreferredShares}
            </Button>
          )}
          {ordEquityInstrumentsExist && (
            <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
              <span
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__icon`],
                  styles[`${PARENT_CLASSNAME}__icon--ord`],
                ])}
              />
              {InstrumentType.OrdinaryEquity}
            </Button>
          )}
          {sweetEquityInstrumentsExist && (
            <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
              <span
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__icon`],
                  styles[`${PARENT_CLASSNAME}__icon--sweet`],
                ])}
              />
              {l('_SweetEquityTitle')}
            </Button>
          )}
        </div>
        <div className={styles[`${PARENT_CLASSNAME}__chart`]}>
          {[...Array(MAX_LABEL_ITEMS)].map((_, i) => (
            <div
              key={`grid-line-${i}`}
              className={classNames([
                styles[`${PARENT_CLASSNAME}__grid-line`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i + 1}`],
              ])}
              data-grid-value={abbreviatedValueFormatter({
                value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * (i + 1) * multiplier,
                ...amountValueFormatterProps,
              })}
            />
          ))}
          <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
            <Pwerm2InstrumentColumn
              instrumentBlocks={renderRanks(entryStackStructure)}
              isExpanded={isExpanded}
              heightPct={(100 / calculatedLabelTopValue) * totalInvestments}
              isEntryColumn
              forecastData={entryResults}
              onClick={() => {
                if (isTotalEquityChartWithModalEnabled) {
                  setSelectedStructure(getSelectedStructure(true));
                  setIsTotalEquityChartModalVisible(true);
                }
              }}
            />
            {[...Array(chartForecastsCount)].map((_, i) => (
              <Fragment key={'forecast-wrapper-' + i}>
                <Pwerm2InstrumentColumn
                  instrumentBlocks={renderRanks(stacksPerForecast[i], i)}
                  isExpanded={isExpanded}
                  heightPct={(100 / calculatedLabelTopValue) * totalEquityPerForecast[i]}
                  isLastForecast={i === chartForecastsCount - 1}
                  forecastData={forecastResults[i]}
                  onClick={() => {
                    if (isTotalEquityChartWithModalEnabled) {
                      setSelectedStructure(getSelectedStructure(false, i));
                      setIsTotalEquityChartModalVisible(true);
                    }
                  }}
                />
                {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                  <div
                    className={classNames(styles['graph__expander-container'], {
                      [styles['graph__expander-container--secondary']]: !isExpanded,
                    })}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <div
          className={classNames([
            styles[`${PARENT_CLASSNAME}__chart`],
            styles[`${PARENT_CLASSNAME}__chart--alternate`],
            styles[`${PARENT_CLASSNAME}__chart--${getMaxNetDebtAxisCount()}`],
          ])}>
          {[...Array(getMaxNetDebtAxisCount() + 1)].map((_, i) => (
            <Fragment key={`grid-line-debt-${i}`}>
              <div
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__grid-line`],
                  styles[`${PARENT_CLASSNAME}__grid-line--${i}`],
                  styles[`${PARENT_CLASSNAME}__grid-line--${i}-negative`],
                ])}
                data-grid-value={abbreviatedValueFormatter({
                  value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * -i * multiplier,
                  ...amountValueFormatterProps,
                })}
              />
            </Fragment>
          ))}
          <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
            <div
              className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
                [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
              })}>
              <div
                onClick={() => {
                  if (isTotalEquityChartWithModalEnabled) {
                    setSelectedStructure(getSelectedStructure(true));
                    setIsTotalEquityChartModalVisible(true);
                  }
                }}
                className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                  [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
                  [styles['cursor-pointer']]: isTotalEquityChartWithModalEnabled,
                })}
                style={{
                  height:
                    (100 /
                      ((calculatedLabelTopValue / MAX_LABEL_ITEMS) * getMaxNetDebtAxisCount())) *
                      getInitialNetDebtValue() +
                    '%',
                }}>
                <Tooltip
                  key="debt-tooltip"
                  placement={TooltipPlacement.Right}
                  content={<Pwerm2ForecastTooltip forecastData={entryResults} isEntryTooltip />}
                  className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                    [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
                  })}>
                  <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
                </Tooltip>
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>{l('_Entry')}</div>
            </div>
            {[...Array(chartForecastsCount)].map((_, i) => (
              <Fragment key={'debt-item-' + i}>
                <Pwerm2NetDebtColumn
                  maxNetDebtAxisCount={getMaxNetDebtAxisCount()}
                  isExpanded={isExpanded}
                  chartForecastsCount={chartForecastsCount}
                  forecastYear={forecastData[i]?.forecastYear}
                  columnIndex={i}
                  netDebt={Number(getCaseOrProjectNetDebt(i))}
                  tooltipContent={<Pwerm2ForecastTooltip forecastData={forecastResults[i]} />}
                  labelTopValue={calculatedLabelTopValue}
                  setSelectedStructure={setSelectedStructure}
                  setIsTotalEquityChartModalVisible={setIsTotalEquityChartModalVisible}
                  getSelectedStructure={getSelectedStructure}
                />
                {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                  <div
                    className={classNames(styles['graph__expander-container'], {
                      [styles['graph__expander-container--secondary']]: !isExpanded,
                    })}>
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      className={styles['graph__expander']}>
                      {isExpanded ? <MinusIcon /> : <SvgPlus />}
                    </button>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <PwermTotalEquityModal
        key={selectedStructure?.forecastDate}
        isOpen={isTotalEquityChartModalVisible}
        structure={selectedStructure}
        onClose={() => setIsTotalEquityChartModalVisible(false)}
        isEntryStack={selectedStructure?.forecastDate === investmentDate}
      />
    </>
  );
};

export default Pwerm2TotalEquityChartPwerm;
