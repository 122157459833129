import { ReactElement, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormModel } from '@app/modules/projects/inputs/project-admin/project-form/project-form-types';
import styles from './tabs.module.scss';
import TabTitle, { Props as TabTitleProps } from './TabTitle/TabTitle';

type Props = {
  children: ReactElement<TabTitleProps>[];
  preSelectedTabIndex?: number;
  formMethods: UseFormReturn<ProjectFormModel, any, undefined>;
  setName: any;
  enumName: Record<string, string>;
};

const Tabs = (props: Props): JSX.Element => {
  const { children, preSelectedTabIndex, formMethods, setName, enumName } = props;

  // First tab is shown by default
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(preSelectedTabIndex ?? 0);

  return (
    <div>
      <ul className={styles.ull}>
        {children.map((item, index) => (
          <TabTitle
            formMethods={formMethods}
            key={item.props.title}
            title={item.props.title}
            index={index}
            isActive={index === selectedTabIndex}
            setSelectedTab={setSelectedTabIndex}
            setName={setName}
            enumName={enumName}
          />
        ))}
      </ul>
      {/* show selcted tab by index*/}
      {children[selectedTabIndex]}
    </div>
  );
};

export default Tabs;
