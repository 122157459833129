import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { percentageValidator, getRequiredValidator } from '@core/validations/hook-forms/validators';
import { swapMaskForValueDisplay } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { Card } from '@app/shared/components/card/Card';
import styles from '../deal-thesis/deal-thesis-page.module.scss';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { Navigate, useParams } from 'react-router-dom';
import * as projectActions from '@core/store/project-slice';
import {
  CaseDto,
  FinancialMetricDefinitionDto,
  HistoricForecastDto,
  InstrumentInitialValuesDto,
} from '@app/shared/models/contracts/project-dto';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { cloneDeep, cloneForecast, groupBy, instrumentsSortFn, sumBy } from '@app/shared/helpers';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import BaseModal from '@app/shared/components/modal/BaseModal';
import Button from '@app/shared/components/button/Button';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { SelectOption } from '@app/shared/models/option';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { NotificationMessageType } from '@app/shared/components/notification-message/notification-message-enums';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { EmptyValues } from '@app/shared/constants/empty-values';
import SvgTrash from '@app/shared/icons/Trash';
import useIsReadOnly from '@core/hooks/customUseIsReadOnly';
import { CalcMethod, ReduceYearsType } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '../../RouteConstants';
import { setDealThesisSelectedTab, setShowHistoricDataColumns } from '@core/store/ui-values-slice';
import { DealThesisCaseTable2 } from './deal-thesis-case-table-2/DealThesisCaseTable2';
import { useLocale } from '@app/core/hooks/useLocale';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';
import KeyFinancialMetrics from './deal-thesis-case-table-2/key-financial-metrics/KeyFinancialMetrics';
import { Toggle, ToggleSize } from '@app/shared/components/toggle/Toggle';
import { ReduceYearsModal } from '@app/shared/components/modal/reduce-years-modal/ReduceYearsModal';
import { getWholeYearsBetweenDates } from './deal-thesis-utils/DealThesisHelpers';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import {
  InstrumentDefinitionAndInitialValues,
  selectCapitalStructure,
  selectCapitalStructures,
  selectSlnAndPrefSharesInstrumentsWithInitialValues,
} from '@app/core/store/capital-structure-slice-selectors';

export interface KeyFinancialMetrics {
  metricId: string;
  narrative: string;
  forecast: Nullable<number>[];
}

export interface DealThesisFormModel extends CaseDto {
  slnAndPrefSharesInstruments: InstrumentDefinitionAndInitialValues[];
  numberOfForecastYears: number;
  historicForecasts: HistoricForecastDto[];
  numberOfHistoricYears: number;
  historicalYear: string;
  exitValueDriverLabel: string;
  historicalExitValueDriver: Nullable<number>;
  keyFinancialMetrics: KeyFinancialMetrics[];
  activeMetric: string;
}

export const DealThesisForm2 = () => {
  const PARENT_CLASSNAME = 'case-form-card';
  const { l } = useLocale();
  const { isDevFeatureEnabled } = useDevFeatures();
  const dispatch = useAppDispatch();
  const { navigate, generatePath } = usePwcNavigate();
  const { caseId } = useParams();
  const { isPwermInputsValid, projectDraft: project } = useAppSelector((state) => state.project);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const maxHistoricYears = useAppSelector(
    (state) => state.uiValues.probabilityWeighted?.maxHistoricalEntries
  );
  const shouldShowHistoricData = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  // adjust the coupon values to be displayed as percentages - this will not be required once initial coupons are moved into the Build Structure pages
  const slnAndPrefSharesInstruments = useAppSelector(
    selectSlnAndPrefSharesInstrumentsWithInitialValues
  )
    .map((ins) => {
      if (ins.initialValues?.coupon) {
        return {
          ...ins,
          initialValues: {
            ...ins.initialValues,
            coupon: {
              ...ins.initialValues.coupon,
              value: (ins.initialValues.coupon?.value ?? 0) * 100,
            },
          },
        };
      }

      return ins;
    })
    .sort(instrumentsSortFn);

  // caseId may be undefined momentarily when deleting a case as the project updates ahead of the navigation
  const caseIdIsInvalid = !project.pwermInput.cases.some((x) => x.caseId === caseId);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const historicData = cloneDeep(project.pwermInput.historicForecasts);
  const activeCase = caseData?.narrative;
  const [isDeleteCaseModalVisible, setIsDeleteCaseModalVisible] = useState(false);
  const [isForecastYearRemovalModalVisible, setIsForecastYearRemovalModalVisible] = useState(false);
  const [isHistoricForecastYearRemovalModalVisible, setIsHistoricForecastYearRemovalModalVisible] =
    useState(false);
  const isReadOnly = useIsReadOnly();
  const caseExists = project.pwermInput.cases.some((x) => x.caseId === caseId);
  const firstYearEndDate = caseData ? caseData.forecasts[0].forecastYear : '';
  const capitalStructures = useAppSelector(selectCapitalStructures);
  const capitalStructureKey = Object.keys(capitalStructures)[0];
  const capitalStructure = useAppSelector(selectCapitalStructure);

  const maximumHistoricYearsAllowed = getWholeYearsBetweenDates(
    firstYearEndDate,
    project.investmentDate
  );

  useEffect(() => {
    dispatch(setDealThesisSelectedTab(caseId));
  }, [caseId, dispatch]);

  useEffect(() => {
    if (!caseExists) {
      navigate('../');
    }
  }, [project.pwermInput.cases, caseExists, navigate]);

  const valuationValueDriver = getPwermValuationValueDriver(project);

  const defaultFormValues: DealThesisFormModel = {
    caseId: caseId ?? '',
    narrative: caseData?.narrative ?? '',
    probability: caseData?.probability ?? 0,
    capitalStructureId: caseData?.capitalStructureId ?? '',
    eventSetId: caseData?.eventSetId ?? null,
    multiples: caseData?.multiples ?? [],
    netDebtItems: caseData?.netDebtItems ?? [],
    forecasts: caseData?.forecasts ?? [],
    numberOfForecastYears: caseData?.forecasts.length ?? 3,
    historicForecasts: historicData ?? [],
    numberOfHistoricYears: historicData.length ?? 0,
    historicalYear: project.pwermInput.valuationDateNarrative,
    keyFinancialMetrics: Object.entries(project.pwermInput.metricDefinitions).map(
      ([key, value]) => {
        const valuationDateMetrics = project.pwermInput.valuationDateMetrics[key];
        const forecast: Nullable<number>[] = caseData
          ? Object.values(caseData?.forecasts).map((forecast) => forecast.metricValues[key])
          : [];
        forecast.unshift(valuationDateMetrics);
        return {
          metricId: key,
          narrative: value.narrative,
          forecast: forecast,
        };
      }
    ),
    historicalCashValue:
      caseData?.historicalCashValue || caseData?.historicalCashValue === 0
        ? caseData?.historicalCashValue
        : null,
    exitValueDriverLabel:
      project.pwermInput.metricDefinitions[project.pwermInput.valueDriverActiveMetric].narrative,
    historicalExitValueDriver:
      valuationValueDriver.value || valuationValueDriver.value === 0
        ? valuationValueDriver.value
        : null,
    slnAndPrefSharesInstruments: slnAndPrefSharesInstruments ?? [],
    activeMetric: project.pwermInput.valueDriverActiveMetric,
  };

  const formMethods = useForm<DealThesisFormModel>({
    ...formConfigBase,
    defaultValues: defaultFormValues,
  });

  const { append: appendForecastsField, remove: removeForecastsField } = useFieldArray({
    name: 'forecasts',
    control: formMethods.control,
  });

  const { append: appendHistoricForecastsField, remove: removeHistoricForecastsField } =
    useFieldArray({
      name: 'historicForecasts',
      control: formMethods.control,
    });

  const { handleSubmit, reset, getValues, trigger } = formMethods;

  const [countOfForecastYears, setCountOfForecastYears] = useState<number>(
    defaultFormValues.numberOfForecastYears
  );
  const [countOfHistoricYears, setCountOfHistoricYears] = useState<number>(
    defaultFormValues.numberOfHistoricYears
  );

  const numberOfForecastYearsOptions: SelectOption[] = [...Array(10).keys()].map((item) => ({
    viewValue: item + 1,
    value: item + 1,
  }));

  const numberOfHistoricYearsOptions: SelectOption[] = [
    ...Array((maximumHistoricYearsAllowed ?? 0) + 1).keys(),
  ]
    .slice(0, (maxHistoricYears ?? 0) + 1)
    .map((item) => ({
      viewValue: item,
      value: item,
    }));

  const caseProbabilitiesTotal = sumBy(project.pwermInput.cases, (caseItem) =>
    Number(caseItem.probability)
  );

  const probabilitiesTotalWarningMessage = l('_AllProbabilitiesMustBe100Error', {
    type: l('_CaseLower'),
    amount: !Number.isNaN(caseProbabilitiesTotal)
      ? numberValueFormatter({ value: caseProbabilitiesTotal!, ...percentageValueFormatterProps })
      : EmptyValues.EnDash,
  });

  const isStructureTheSame =
    Object.keys(groupBy(project.pwermInput.cases, (c) => c.forecasts.length)).length === 1 &&
    Object.keys(groupBy(project.pwermInput.cases, (c) => c.multiples.length)).length === 1;
  const caseStruturesDiffersWarningMessage = l('_NoOfForecastYearsExitMultiplesMismatchWarning');

  useEffect(() => {
    reset(defaultFormValues);
    setCountOfForecastYears(defaultFormValues.numberOfForecastYears);
    setCountOfHistoricYears(defaultFormValues.numberOfHistoricYears);
    trigger();
  }, [reset, caseId]); // eslint-disable-line react-hooks/exhaustive-deps

  // re-trigger validation because some validation relies on the updated project draft (Key Financial Metrics narrative uniqueness)
  useEffect(() => {
    trigger();
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleForecastYearQuantityChange = () => {
    if (Number(countOfForecastYears) > Number(getValues().numberOfForecastYears)) {
      setIsForecastYearRemovalModalVisible(true);
    } else if (Number(countOfForecastYears) < Number(getValues().numberOfForecastYears)) {
      addForecastYears();
      setCountOfForecastYears(getValues().numberOfForecastYears);
    }
  };

  const handleHistoricForecastYearQuantityChange = () => {
    if (Number(countOfHistoricYears) > Number(getValues().numberOfHistoricYears)) {
      setIsHistoricForecastYearRemovalModalVisible(true);
    } else if (Number(countOfHistoricYears) < Number(getValues().numberOfHistoricYears)) {
      addHistoricForecastYears();
      setCountOfHistoricYears(getValues().numberOfHistoricYears);
    }
  };

  const revertForecastYears = () => {
    setIsForecastYearRemovalModalVisible(false);
    formMethods.setValue('numberOfForecastYears', countOfForecastYears);
    submitData();
  };

  const removeForecastYears = () => {
    const forecasts = caseData?.forecasts ?? [];

    removeForecastsField(
      [
        ...Array(Number(countOfForecastYears) - Number(getValues().numberOfForecastYears)).keys(),
      ].map((index) => {
        return forecasts.length - index - 1;
      })
    );
    submitData();
    setIsForecastYearRemovalModalVisible(false);
    setCountOfForecastYears(getValues().numberOfForecastYears);
  };

  const addForecastYears = () => {
    const forecasts = caseData?.forecasts ?? [];
    const lastCaseItemForecast = forecasts?.[forecasts?.length - 1];
    [...Array(Number(getValues().numberOfForecastYears) - Number(countOfForecastYears)).keys()].map(
      (index) => {
        const clonedForecast = cloneForecast(lastCaseItemForecast);
        appendForecastsField({
          ...clonedForecast,
          forecastYear: new Date(clonedForecast.forecastYear).addYears(index + 1).toISODateString(),
        });
      }
    );
  };

  const revertHistoricForecastYears = () => {
    setIsHistoricForecastYearRemovalModalVisible(false);
    formMethods.setValue('numberOfHistoricYears', countOfHistoricYears);
    submitData();
  };

  const removeHistoricForecastYears = () => {
    const historicForecasts = formMethods.getValues().historicForecasts ?? [];
    removeHistoricForecastsField(
      [
        ...Array(Number(countOfHistoricYears) - Number(getValues().numberOfHistoricYears)).keys(),
      ].map((index) => {
        return historicForecasts.length - index - 1;
      })
    );
    submitData();
    setIsHistoricForecastYearRemovalModalVisible(false);
    setCountOfHistoricYears(getValues().numberOfHistoricYears);
  };

  const addHistoricForecastYears = () => {
    const historicForecasts = formMethods.getValues().historicForecasts ?? [];
    const firstYearEndDate = caseData ? caseData.forecasts[0].forecastYear : '';
    const lastCaseItemHistoricForecast = historicForecasts?.[historicForecasts?.length - 1];

    if (historicForecasts.length === 0) {
      [...Array(Number(getValues().numberOfHistoricYears)).keys()].map((index) => {
        appendHistoricForecastsField({
          ...historicData,
          date: new Date(firstYearEndDate).subtractYears(index + 1).toISODateString(),
          metricValues: {
            ...formMethods.getValues().keyFinancialMetrics.reduce((acc, metric) => {
              acc[metric.metricId] = null;
              return acc;
            }, {} as Record<string, Nullable<number>>),
          },
          historicNetDebt: null,
          operationalFreeCashFlow: null,
        });
      });
      dispatch(setShowHistoricDataColumns(true));
    } else {
      [
        ...Array(Number(getValues().numberOfHistoricYears) - Number(countOfHistoricYears)).keys(),
      ].map((index) => {
        appendHistoricForecastsField({
          ...lastCaseItemHistoricForecast,
          date: new Date(lastCaseItemHistoricForecast.date)
            .subtractYears(index + 1)
            .toISODateString(),
        });
      });
    }
  };

  const submitData = async () => {
    const {
      historicalYear,
      keyFinancialMetrics,
      activeMetric,
      historicForecasts,
      slnAndPrefSharesInstruments: slnAndPrefSharesInstruments,
      ...caseDtoValues
    } = getValues();
    const newMetricDefinitions = keyFinancialMetrics.reduce((acc, metric) => {
      acc[metric.metricId] = {
        narrative: metric.narrative,
      };
      return acc;
    }, {} as Record<string, FinancialMetricDefinitionDto>);

    const newValuationDateMetrics = keyFinancialMetrics.reduce((acc, metric) => {
      acc[metric.metricId] = metric.forecast[0] ?? 0;
      return acc;
    }, {} as Record<string, number>);

    const updatedHistoricYears = cloneDeep(historicForecasts);

    if (!isReadOnly) {
      const updatedCasesData = project.pwermInput.cases.map((caseItem) => {
        if (caseItem.caseId !== caseId) {
          return {
            ...caseItem,
            forecasts: caseItem.forecasts.map((forecast) => {
              const metricValues = cloneDeep(forecast.metricValues) || {};
              const metricIds = Object.keys(metricValues);
              const missingMetrics = keyFinancialMetrics.filter(
                (metric) => !metricIds.includes(metric.metricId)
              );
              // Add new metrics to cases
              const addMetricValues = missingMetrics.reduce((acc, metric) => {
                acc[metric.metricId] = 0;
                return acc;
              }, {} as Record<string, number>);

              // remove case's metrics that were deleted from the metric definitions
              Object.keys(metricValues).forEach((metricId) => {
                if (!keyFinancialMetrics.some((metric) => metric.metricId === metricId)) {
                  delete metricValues[metricId];
                }
              });
              return {
                ...forecast,
                metricValues: {
                  ...metricValues,
                  ...addMetricValues,
                },
              };
            }),
          };
        }

        const forecasts = caseDtoValues.forecasts.map((forecast, index) => {
          return {
            ...forecast,
            metricValues: keyFinancialMetrics.reduce((acc, metric) => {
              acc[metric.metricId] = metric.forecast[index + 1] ?? 0;
              return acc;
            }, {} as Record<string, number>),
          };
        });
        return {
          ...caseItem,
          ...cloneDeep(caseDtoValues),
          caseId: caseItem.caseId,
          forecasts: cloneDeep(forecasts),
        };
      });

      // TODO: This will not be required once initial coupons are moved into the Build Structure pages
      const updatedInstrumentValues: Record<string, InstrumentInitialValuesDto> = Object.entries(
        capitalStructure.initialValues
      ).reduce((acc, [instrumentId, initialValues]) => {
        const formInstrument = slnAndPrefSharesInstruments.find(
          (instrument) => instrument.instrumentId === instrumentId
        );

        if (formInstrument && formInstrument.initialValues?.coupon) {
          acc[instrumentId] = {
            ...initialValues,
            coupon: {
              ...initialValues.coupon!,
              value: (formInstrument.initialValues.coupon.value ?? 0) / 100,
              date: formInstrument.initialValues.coupon.date,
            },
          };
        } else {
          acc[instrumentId] = { ...initialValues };
        }

        return acc;
      }, {} as Record<string, InstrumentInitialValuesDto>);

      await dispatch(
        projectActions.updateProjectDraft({
          project: {
            ...project,
            pwermInput: {
              ...project.pwermInput,
              cases: [...updatedCasesData],
              valuationDateNarrative: historicalYear,
              metricDefinitions: {
                ...newMetricDefinitions,
              },
              valuationDateMetrics: {
                ...newValuationDateMetrics,
              },
              valueDriverActiveMetric: activeMetric,
              historicForecasts: updatedHistoricYears,
            },
            capitalStructures: {
              ...capitalStructures,
              [capitalStructureKey]: {
                ...capitalStructure,
                initialValues: updatedInstrumentValues,
              },
            },
          },
        })
      ).unwrap();
    }
  };

  const handleCaseRemoval = (caseId: string | undefined) => {
    dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: [...project.pwermInput.cases.filter((i) => i.caseId !== caseId)],
          },
        },
      })
    );
    setIsDeleteCaseModalVisible(false);
    const index = project.pwermInput.cases.findIndex((item) => item.caseId === caseId);
    navigate(
      generatePath('../:caseId', {
        caseId: project.pwermInput.cases[index === 0 ? index + 1 : index - 1].caseId,
      })
    );
  };

  // the ternary operator is used to safely handle the scenario where a case is added, and attempts to render, but the results are not yet available
  const numberOfResultsForecastYears = results.cases[caseId!]
    ? Object.keys(results.cases[caseId!].forecastDates).length
    : 0;
  const numberOfResultsHistoricForecastYears = results.cases[caseId!]
    ? Object.keys(results.cases[caseId!].historicDates?.historicForecastYears ?? {}).length
    : 0;
  const numberOfResultsHistoricEvents = results.cases[caseId!]
    ? Object.keys(results.cases[caseId!].historicDates?.historicEventYears ?? {}).length
    : 0;

  useEffect(() => {
    // if the case is not yet available in the results, return
    if (results.cases[caseId!] === undefined) {
      return;
    }

    // resolve concurrency issue
    setTimeout(() => trigger(), 0);
    // numberOfResultsForecastYears and numberOfResultsHistoricForecastYears are included in the dependency array to ensure that the form is revalidated a change to either is made
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, caseId, numberOfResultsForecastYears, numberOfResultsHistoricForecastYears]);

  // return null if results.cases[caseId!] is undefined, which can happen if a case is added, and attempts to render, but the results are not yet available
  if (results.cases[caseId!] === undefined || caseIdIsInvalid) {
    return null;
  }

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  if (!isPwermInputsValid) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(submitData, submitData)}>
        {caseProbabilitiesTotal !== 100 && (
          <div className={styles['notification']}>
            <NotificationMessage>{probabilitiesTotalWarningMessage}</NotificationMessage>
          </div>
        )}
        {!isStructureTheSame && (
          <div className={styles['notification']}>
            <NotificationMessage type={NotificationMessageType.warning}>
              {caseStruturesDiffersWarningMessage}
            </NotificationMessage>
          </div>
        )}

        <Card className={styles[PARENT_CLASSNAME]}>
          <FormField
            name="narrative"
            label={l('_CaseNarrativeTitle')}
            required
            rules={{
              ...getRequiredValidator(),
            }}
            isInputMasked={false}
            fieldValueMask={swapMaskForValueDisplay}
            isGapless
          />
          <FormField
            name="probability"
            label={l('_CaseProbabilityTitle')}
            required
            rules={{
              ...getRequiredValidator(),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
          <FormSelect
            name="numberOfForecastYears"
            label={l('_NumberOfForecastYears')}
            onBlur={() => handleForecastYearQuantityChange()}
            required
            rules={{
              ...getRequiredValidator(),
            }}
            options={numberOfForecastYearsOptions}
            isGapless
          />
          {numberOfHistoricYearsOptions.length > 1 &&
            isDevFeatureEnabled(DevFeature.DealThesisHistoricalColumns) && (
              <FormSelect
                name="numberOfHistoricYears"
                label={l('_NumberOfHistoricYears')}
                onBlur={() => handleHistoricForecastYearQuantityChange()}
                options={numberOfHistoricYearsOptions}
                isGapless
              />
            )}
          {(numberOfResultsHistoricForecastYears > 0 || numberOfResultsHistoricEvents > 0) &&
            isDevFeatureEnabled(DevFeature.DealThesisERF) && (
              <span className={styles[`${PARENT_CLASSNAME}__toggle`]}>
                <Toggle
                  name="isHistoricDataVisible"
                  label={l('_ShowHistoricData')}
                  toggleSize={ToggleSize.Medium}
                  labelTextClassName={styles['project-details-toggle-label']}
                  onChange={(e) => dispatch(setShowHistoricDataColumns(e.target.checked))}
                  data-testid="historical-cash-value-toggle"
                  forceChecked={shouldShowHistoricData}
                />
              </span>
            )}
          <div className={styles[`${PARENT_CLASSNAME}__action`]}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              startIcon={<SvgTrash />}
              disabled={project.pwermInput.cases.length === 1}
              onClick={() => setIsDeleteCaseModalVisible(true)}>
              Delete Case
            </Button>
          </div>
          <BaseModal
            size={ModalSize.Small}
            title={l('_DeleteActiveCase', { case: activeCase })}
            buttonConfirmLabel={l('_DeleteCaseTitle')}
            buttonCancelLabel={l('_Cancel')}
            onCloseButtonClick={() => setIsDeleteCaseModalVisible(false)}
            onConfirm={() => handleCaseRemoval(caseId)}
            onCancel={() => setIsDeleteCaseModalVisible(false)}
            isOpen={isDeleteCaseModalVisible}>
            {l('_ThisWillDeleteActiveCaseAndAnyDataYouveEntered', { case: activeCase })}
          </BaseModal>
          <ReduceYearsModal
            onCloseButtonClick={() => revertForecastYears()}
            onCancel={() => revertForecastYears()}
            onConfirm={() => removeForecastYears()}
            isOpen={isForecastYearRemovalModalVisible}
            type={ReduceYearsType.Forecast}
          />
          <ReduceYearsModal
            onCloseButtonClick={() => revertHistoricForecastYears()}
            onCancel={() => revertHistoricForecastYears()}
            onConfirm={() => removeHistoricForecastYears()}
            isOpen={isHistoricForecastYearRemovalModalVisible}
            type={ReduceYearsType.Historic}
          />
        </Card>
        <DealThesisCaseTable2 handleDataSubmit={submitData} />
      </form>
    </FormProvider>
  );
};

export const DealThesisPage2: FC = (): JSX.Element => {
  const { cases } = useAppSelector((state) => state.project.projectDraft).pwermInput;
  const storedDealThesisCaseId = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.selectedTab
  );
  const isStoredDealThesisCaseIdValid =
    storedDealThesisCaseId && cases.some((caseItem) => caseItem.caseId === storedDealThesisCaseId);
  const { generatePath } = usePwcNavigate();

  return (
    <PwcNavigate
      route={generatePath(
        ':caseId',
        isStoredDealThesisCaseIdValid
          ? { caseId: storedDealThesisCaseId }
          : { caseId: cases[0].caseId }
      )}
    />
  );
};
