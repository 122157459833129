import React, { FC } from 'react';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import classNames from 'classnames';
import styles from '@app/modules/projects/dashboard/widgets/total-equity/total-equity-chart.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { InstrumentBlock } from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import { InstrumentDefinitionWithId } from '@app/core/store/capital-structure-slice-selectors';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';

interface InstrumentBlockProps {
  instrument: InstrumentBlock<InstrumentDefinitionWithId>;
  forecastIndex?: number;
  isPreModalChart?: boolean;
}

const Pwerm2InstrumentBlockComponent: FC<InstrumentBlockProps> = ({
  instrument,
  forecastIndex,
  isPreModalChart = false,
}): JSX.Element => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isTotalEquityChartWithModalEnabled = isDevFeatureEnabled(
    DevFeature.TotalEquityChartWithModal
  );

  return (
    <div
      key={'instrument-wrapper' + instrument.instrumentId}
      style={{ width: instrument.width + '%' }}>
      <Button
        data-testid="instrument-block"
        appearance={ButtonAppearance.CLEAN}
        key={'instrument-block-' + forecastIndex}
        ignoreReadOnly
        className={classNames(styles['graph__item'], {
          [styles['graph__item--shareholder-loan']]:
            instrument.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
          [styles['graph__item--sweet']]: instrument.isSweetEquity === true,
          [styles['graph__item--preferred-shares']]:
            instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
          [styles['graph__item--pre-modal-chart']]:
            isPreModalChart && isTotalEquityChartWithModalEnabled,
        })}
      />
    </div>
  );
};

export default React.memo(Pwerm2InstrumentBlockComponent);
