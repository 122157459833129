import { useAppSelector } from '@app/core/hooks/redux-hooks';

const useIsErfProject = () => {
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureKey = Object.keys(buildStructures)?.[0];
  const isErfProject = buildStructures?.[capitalStructureKey].isErf;

  return isErfProject;
};

export default useIsErfProject;
