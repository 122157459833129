import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

interface DealThesisEmptyHistoricDataColumnsProps {
  isHistoricEventsSection: boolean;
  isSectionHeader?: boolean;
  isPrimaryCell?: boolean;
  isStartSeparator?: boolean;
  isEndSeparator?: boolean;
  isHeaderTertiary?: boolean;
  isHeaderAlternate?: boolean;
  isPrimaryCellHeader?: boolean;
}

const DealThesisEmptyHistoricDataColumns: FC<DealThesisEmptyHistoricDataColumnsProps> = ({
  isHistoricEventsSection,
  isSectionHeader = false,
  isPrimaryCell = false,
  isStartSeparator = false,
  isEndSeparator = false,
  isHeaderTertiary = false,
  isHeaderAlternate = false,
  isPrimaryCellHeader = false,
}) => {
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const { caseId } = useParams();
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);

  const caseResults = results.cases[caseId!];
  const numberOfInputHistoricForecastYears = Object.keys(
    project.pwermInput.historicForecasts
  ).length;

  const historicDataForecastYearsResults = Object.keys(
    caseResults.historicDates.historicForecastYears ?? {}
  )
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicEventsYears = Object.keys(
    results.cases[caseId!].historicDates.historicEventYears ?? {}
  ).reverse();

  const emptyColumnsBeforeHistoricDataForecastYearsResults =
    historicEventsYears.length - historicDataForecastYearsResults.length;
  const emptyColumnsBeforeHistoricEventsYears =
    historicDataForecastYearsResults.length - historicEventsYears.length;

  const renderEmptyColumns = () => {
    const columns = [];
    if (showHistoricDataColumns) {
      if (isHistoricEventsSection) {
        columns.push(
          ...Array.from({ length: emptyColumnsBeforeHistoricEventsYears }).map((_, index) =>
            isSectionHeader ? (
              <th
                key={`empty-${index}`}
                className={classNames({
                  'table-primary__cell--section-start-separator': isStartSeparator,
                  'table-primary__cell--header-tertiary ': isHeaderTertiary,
                  'table-primary__cell--header-alternate': isHeaderAlternate,
                  'table-primary__cell--header': isPrimaryCellHeader,
                })}
              />
            ) : (
              <td
                key={`empty-${index}`}
                className={classNames({
                  'table-primary__cell--section-start-separator': isStartSeparator,
                  'table-primary__cell--section-end-separator': isEndSeparator,
                  'table-primary__cell--primary': isPrimaryCell,
                })}
              />
            )
          )
        );
      } else {
        columns.push(
          ...Array.from({ length: emptyColumnsBeforeHistoricDataForecastYearsResults }).map(
            (_, index) =>
              isSectionHeader ? (
                <th
                  key={`empty-${index}`}
                  className={classNames({
                    'table-primary__cell--section-start-separator': isStartSeparator,
                    'table-primary__cell--header-tertiary ': isHeaderTertiary,
                    'table-primary__cell--header-alternate': isHeaderAlternate,
                    'table-primary__cell--header': isPrimaryCellHeader,
                  })}
                />
              ) : (
                <td
                  key={`empty-${index}`}
                  className={classNames({
                    'table-primary__cell--section-start-separator': isStartSeparator,
                    'table-primary__cell--section-end-separator': isEndSeparator,
                    'table-primary__cell--primary': isPrimaryCell,
                  })}
                />
              )
          )
        );
      }
    }
    return columns;
  };

  return <>{renderEmptyColumns()}</>;
};

export default DealThesisEmptyHistoricDataColumns;
