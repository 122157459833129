import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import styles from './compatibility-level-upgrade.module.scss';
import Button from '../button/Button';
import { ButtonAppearance, ButtonSize } from '../button/button-enums';
import { selectCurrentProjectCompatibilityLevel } from '@app/core/store/ui-values-slice-selectors';
import { FC } from 'react';

const PARENT_CLASSNAME = 'compatibility-level-upgrade';

interface CompatibilityLevelUpgradeNoticeProps {
  setShowModal: (showModal: boolean) => void;
}

export const CompatibilityLevelUpgradeNotice: FC<CompatibilityLevelUpgradeNoticeProps> = ({
  setShowModal,
}) => {
  const projectLevel = useAppSelector(selectCurrentProjectCompatibilityLevel);

  return (
    <>
      <span className={styles[`${PARENT_CLASSNAME}__upgrade-badge`]}>
        <Button
          onClick={() => setShowModal(true)}
          appearance={ButtonAppearance.CLEAN}
          size={ButtonSize.FLUID}>
          <Pill size={PillSize.Small} theme={PillTheme.Tertiary}>
            {projectLevel?.label}
          </Pill>
        </Button>
      </span>
    </>
  );
};
