import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getDealThesisInputCaseForecastDate,
  getDealThesisInputCaseHistoricForecastDate,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';
import { cloneDeep } from '@app/shared/helpers';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';

const ExitValueDriverSection2: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseResults = results.cases[caseId!];
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const numberOfInputForecastYears = caseData?.forecasts.length;
  // results data sliced such that a decrease in forecast years is immediately rendered without depending on results
  const caseResultsForecasts = Object.entries(caseResults.forecastDates)
    .map(([forecastId, value]) => ({
      forecastId,
      ...value,
    }))
    .slice(0, numberOfInputForecastYears);
  const numberOfResultsForecastYears = Object.keys(caseResults.forecastDates).length;
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseDataForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  const historicDataForecastYearsInputs = cloneDeep(project.pwermInput.historicForecasts).reverse();
  const numberOfInputHistoricForecastYears = historicDataForecastYearsInputs.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicDataForecastYearsResults = Object.entries(
    caseResults.historicDates.historicForecastYears ?? {}
  )
    .map(([historicYears, value]) => ({
      historicYears,
      ...value,
    }))
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicDataEventsYearsResults = Object.keys(
    results.cases[caseId!].historicDates.historicEventYears ?? {}
  ).reverse();
  const maxHistoricalDataLength = Math.max(
    historicDataForecastYearsResults.length,
    historicDataEventsYearsResults.length
  );
  const maxColspanCount =
    (numberOfInputForecastYears ? numberOfInputForecastYears : 0) +
    maxHistoricalDataLength +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;
  const valueDriverName = getPwermValuationValueDriver(project).narrative;
  const valueDriverMetricId = project.pwermInput.valueDriverActiveMetric;

  return (
    <>
      <tbody>
        <tr>
          <th className="table-primary__cell--header">{l('_ExitValueDriverTitle')}</th>
          <td className="table-primary__cell--header" colSpan={maxColspanCount - 1} />
        </tr>
        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator">
            <CellValue value={valueDriverName} placeholder={l('_ExitValueDriver')} />
          </th>
          <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
          {showHistoricDataColumns &&
            historicDataForecastYearsResults.map((_, index) => (
              <td key={index + 'exit-driver-historic'}>
                <CellValue
                  {...amountRules}
                  value={historicDataForecastYearsInputs[index].metricValues[valueDriverMetricId]}
                  alignment={Alignment.Right}
                />
              </td>
            ))}
          <td>
            <CellValue
              {...amountRules}
              value={project.pwermInput.valuationDateMetrics[valueDriverMetricId]}
              alignment={Alignment.Right}
            />
          </td>
          {caseDataForecasts?.map((forecast, index) => (
            <td key={index + 'exit-driver'}>
              <CellValue
                {...amountRules}
                value={forecast.metricValues[valueDriverMetricId]}
                alignment={Alignment.Right}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            {l('_ExitValueDriverYoYGrowth', { exitValueDriver: valueDriverName })}
          </th>
          <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
          {showHistoricDataColumns &&
            historicDataForecastYearsResults.map((historicForecast) => (
              <td key={historicForecast.historicYears}>
                <CellValue
                  {...enteredPercentageViewRules}
                  value={getCalculatedProjectValueInPercent(
                    getDealThesisInputCaseHistoricForecastDate(
                      results,
                      caseId!,
                      historicForecast.historicYears,
                      (historicForecast) => historicForecast.valueDriverYearOnYearGrowth
                    )
                  )}
                />
              </td>
            ))}
          <td />
          {caseResultsForecasts.map((forecast, index) => (
            <td key={index + 'exit-driver-yoy-growth'}>
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getDealThesisInputCaseForecastDate(
                    results,
                    caseId!,
                    forecast.forecastId,
                    (forecast) => forecast.valueDriverYearOnYearGrowth
                  )
                )}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            {l('_ExitValueDriverCAGR', { exitValueDriver: valueDriverName })}
          </th>
          <DealThesisEmptyHistoricDataColumns isHistoricEventsSection={false} />
          {showHistoricDataColumns &&
            historicDataForecastYearsResults.map((historicForecast, index) => (
              <td key={index + 'exit-driver-historic-cagr'}>
                <CellValue
                  {...enteredPercentageViewRules}
                  value={getCalculatedProjectValueInPercent(
                    getDealThesisInputCaseHistoricForecastDate(
                      results,
                      caseId!,
                      historicForecast.historicYears,
                      (historicForecast) => historicForecast.valueDriverCompoundAnnualGrowth
                    )
                  )}
                />
              </td>
            ))}
          <td />
          {caseResultsForecasts.map((forecast, index) => (
            <td key={index + 'exit-driver-cagr'}>
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getDealThesisInputCaseForecastDate(
                    results,
                    caseId!,
                    forecast.forecastId,
                    (forecast) => forecast.valueDriverCompoundAnnualGrowth
                  )
                )}
              />
            </td>
          ))}
          <td />
        </tr>
        <DealThesisSpacerRow2 />
      </tbody>
    </>
  );
};

export default ExitValueDriverSection2;
