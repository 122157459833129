import { FC, useState } from 'react';
import styles from './dashboard-header.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { dateFormatter } from '@app/shared/formatters';
import CurrencyLabel from '@app/shared/components/currency-label/CurrencyLabel';
import { useLocale } from '@app/core/hooks/useLocale';
import { CompatibilityLevelUpgradeNotice } from '@app/shared/components/compatibility-level-upgrade/CompatibilityLevelUpgradeNotice';
import { CompatibilityLevelUpgradeModal } from '@app/shared/components/compatibility-level-upgrade/CompatibilityLevelUpgradeModal';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
interface DashboardHeaderProps {
  isChartScaled?: boolean;
}

const PARENT_CLASSNAME = 'header';
export const DashboardHeader: FC<DashboardHeaderProps> = ({
  isChartScaled = false,
}): JSX.Element => {
  const { compatibilityLevel: compatibilityLevelConfig } = useAppSelector(
    (state) => state.uiValues
  );
  const {
    projectDraft: { status, name, valuationDate, compatibilityLevel },
  } = useAppSelector((state) => state.project);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  const showUpgradeNotice =
    compatibilityLevel !== compatibilityLevelConfig.currentLevel && status !== ProjectStatus.Closed;
  const { l } = useLocale();
  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <dl className={styles[`${PARENT_CLASSNAME}__item-list`]}>
        <dt className={styles[`${PARENT_CLASSNAME}__item-label`]}>{l('_ProjectName')}</dt>
        <dd className={styles[`${PARENT_CLASSNAME}__item-value`]}>
          <span>{name}</span>
          {showUpgradeNotice && (
            <CompatibilityLevelUpgradeNotice setShowModal={setShowUpgradeModal} />
          )}
        </dd>
        <dt className={styles[`${PARENT_CLASSNAME}__item-label`]}>{l('_ValuationDate')}</dt>
        <dd className={styles[`${PARENT_CLASSNAME}__item-value`]}>
          {dateFormatter(valuationDate.toString(), { dateStyle: 'short' })}
        </dd>
      </dl>
      <CurrencyLabel isChartScaled={isChartScaled} />
      <CompatibilityLevelUpgradeModal
        showModal={showUpgradeModal}
        setShowModal={setShowUpgradeModal}
      />
    </div>
  );
};
