import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  abbreviatedValueFormatter,
  numberValueFormatter,
  toPercentageUsingFormatter,
} from '@app/shared/formatters';
import { enumKeyByValue } from '@app/shared/helpers';
import { DenominationMultiplier, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { Pwerm2CalculationDashboardTotalEquityChartYearDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { FC } from 'react';
import styles from '../../widgets/total-equity/total-equity-chart.module.scss';

const PARENT_CLASSNAME = 'total-equity-chart';

interface ForecastTooltipProps {
  forecastData: Pwerm2CalculationDashboardTotalEquityChartYearDto;
  isEntryTooltip?: boolean;
}

export const Pwerm2ForecastTooltip: FC<ForecastTooltipProps> = ({
  forecastData,
  isEntryTooltip = false,
}) => {
  const { l } = useLocale();
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const { weightedEv, totalProceeds, proceedsByOwner, sponsorMoM, sponsorIRR } = forecastData;
  const sponsorProceeds = proceedsByOwner[enumKeyByValue(OwnerType, OwnerType.Institution)];
  const managementProceeds = proceedsByOwner[enumKeyByValue(OwnerType, OwnerType.Management)];
  const sponsorLabel = l('_Sponsor');
  const managementLabel = l('_Management');

  return (
    <div key={'forecast-tooltip-' + forecastData.forecastDate}>
      <dl className="definition-list">
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{isEntryTooltip ? l('_InitialEV') : l('_WeightedEV')}</dt>
            <dd>{abbreviatedValueFormatter({ value: weightedEv * multiplier })}</dd>
          </div>
          <div className="definition-list__row-item">
            <dt>
              {isEntryTooltip ? l('_InitialInvestment') : l('_WeightedTotalAttributableEquity')}
            </dt>
            <dd>{abbreviatedValueFormatter({ value: totalProceeds * multiplier })}</dd>
          </div>
        </div>
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>
              {isEntryTooltip
                ? l('_TotalOwnerInvestment', { Owner: sponsorLabel })
                : l('_TotalOwnerProceeds', { Owner: sponsorLabel })}
            </dt>
            <dd>{abbreviatedValueFormatter({ value: sponsorProceeds * multiplier })}</dd>
          </div>
          <div className="definition-list__row-item">
            <dt>
              {isEntryTooltip
                ? l('_TotalOwnerInvestment', { Owner: managementLabel })
                : l('_TotalOwnerProceeds', { Owner: managementLabel })}
            </dt>
            <dd>{abbreviatedValueFormatter({ value: managementProceeds * multiplier })}</dd>
          </div>
        </div>
        {!isEntryTooltip && (
          <div className="definition-list__row">
            <div className="definition-list__row-item">
              <dt>{l('_SponsorMoM')}</dt>
              <dd>{numberValueFormatter({ value: sponsorMoM, fraction: 2, valueSuffix: 'x' })}</dd>
            </div>
            <div className="definition-list__row-item">
              <dt>{l('_SponsorIRR')}</dt>
              <dd>{toPercentageUsingFormatter(sponsorIRR)}</dd>
            </div>
          </div>
        )}
      </dl>
      <div className={styles[`${PARENT_CLASSNAME}__forecast-tooltip-footer`]}>
        <b>{l('_ClickToEnlarge')}</b>
      </div>
    </div>
  );
};
