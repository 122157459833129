import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { FC, Fragment, useState } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { ViewChangeType } from '@app/shared/models/contracts/enums/shared-enums';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis-2/DealThesisPage2';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto,
  Pwerm2InputYearDebtInstrumentCalculationResultsPeriodTotalDto,
} from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import SvgAnalysis from '@app/shared/icons/Analysis';
import styles from './deal-thesis-sln-and-pref-share-section.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { CorkscrewModal } from './CorkscrewModal';
import { translationKeys } from 'src/locale/setupI18n';
import { mapCouponTypeInterestToTranslation } from '../../deal-thesis-utils/CouponInterestTranslationHelper';
import DealThesisEmptyHistoricDataColumns from '../../deal-thesis-utils/DealThesisEmptyHistoricDataColumns';
import { compareDeep } from '@app/shared/helpers/clone/compare-deep';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';
import classNames from 'classnames';

interface DealThesisCaseSlnAndPrefShareSectionProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseSlnAndPrefShareSectionERF: FC<
  DealThesisCaseSlnAndPrefShareSectionProps
> = () => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseResults = results.cases[caseId!];
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)!;
  const numberOfInputForecastYears = caseData?.forecasts.length ?? 0;
  const caseForecasts = Object.entries(caseResults.forecastDates).slice(
    0,
    numberOfInputForecastYears
  );
  const numberOfInputHistoricForecastYears = project.pwermInput.historicForecasts.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicForecasts = Object.entries(caseResults.historicDates.historicForecastYears ?? {})
    .map(([historicYears, value]) => ({
      historicYears,
      ...value,
    }))
    .slice(0, numberOfInputHistoricForecastYears)
    .reverse();
  const historicEventsData = Object.entries(caseResults.historicDates.historicEventYears ?? {}).map(
    ([historicEventsYear, value]) => ({
      historicEventsYear,
      ...value,
    })
  );
  const maxHistoricalDataLength = Math.max(historicForecasts.length, historicEventsData.length);

  const [isCorkscrewModalOpen, setIsCorkscrewModalOpen] = useState(false);
  const [instrumentId, setInstrumentId] = useState('');
  const [instrumentNarrative, setInstrumentNarrative] = useState('');
  const [eventData, setEventData] = useState<
    Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto[]
  >([]);
  const [shouldHidePrevious, setShouldHidePrevious] = useState(true);
  const [shouldHideNext, setShouldHideNext] = useState(true);

  const { control } = useFormContext<DealThesisFormModel>();
  const { fields } = useFieldArray({
    name: 'slnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (caseForecasts ? caseForecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    maxHistoricalDataLength;

  const determineCorkscrewNavigationButtonVisibility = (instrumentId: string, date: string) => {
    const allDates = [];

    const historicDates = Object.keys(
      results.cases[caseId!].historicDates.historicEventYears ?? {}
    );

    if (showHistoricDataColumns) {
      allDates.push(...historicDates);
    }

    allDates.push(project.valuationDate);

    const forecastDates = Object.keys(results.cases[caseId!].forecastDates);
    allDates.push(...forecastDates);

    const hasMultipleEvents = (date: string) => {
      if (forecastDates.includes(date)) {
        const forecastEvents =
          results?.cases?.[caseId!]?.forecastDates?.[date]?.shareholderDebtInstrumentsEvents?.[
            instrumentId
          ]?.periodByEvent;
        return forecastEvents ? forecastEvents.length > 1 : false;
      } else if (historicDates.includes(date)) {
        const historicEvents =
          results?.cases?.[caseId!]?.historicDates?.historicEventYears?.[date]
            ?.shareholderDebtInstrumentsEvents?.[instrumentId]?.periodByEvent;
        return historicEvents ? historicEvents.length > 1 : false;
      } else {
        const valuationEvents =
          results?.cases?.[caseId!]?.valuationDate?.shareholderDebtInstrumentsEvents?.[instrumentId]
            ?.periodByEvent;
        return valuationEvents ? valuationEvents.length > 1 : false;
      }
    };

    const firstDateWithMultipleEvents = allDates.find((date) => hasMultipleEvents(date));
    const lastDateWithMultipleEvents = allDates
      .slice()
      .reverse()
      .find((date) => hasMultipleEvents(date));

    const isFirstDateWithMultipleEvents = date === firstDateWithMultipleEvents;
    const isLastDateWithMultipleEvents = date === lastDateWithMultipleEvents;

    return {
      shouldHidePrevious: isFirstDateWithMultipleEvents,
      shouldHideNext: isLastDateWithMultipleEvents,
    };
  };

  const handleCorkscrewButtonClick = (
    isOpen: boolean,
    instrumentId: string,
    instrumentNarrative: string,
    eventData: Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto[],
    eventDate: string
  ) => {
    const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
      instrumentId,
      eventDate
    );
    setShouldHidePrevious(shouldHidePrevious);
    setShouldHideNext(shouldHideNext);
    setIsCorkscrewModalOpen(isOpen);
    setInstrumentId(instrumentId);
    setInstrumentNarrative(instrumentNarrative);
    setEventData(eventData);
  };

  const handleCorkscrewForecastChange = (
    instrumentId: string,
    changeType: keyof typeof ViewChangeType
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allDates: [string, any][] = [];
    const historicDates = Object.entries(
      results.cases[caseId!].historicDates.historicEventYears ?? {}
    );

    if (showHistoricDataColumns) {
      allDates.push(...historicDates);
    }

    allDates.push([project.valuationDate, results.cases[caseId!].valuationDate]);

    const forecastDates = Object.entries(results.cases[caseId!].forecastDates);
    allDates.push(...forecastDates);

    const currentEventDate = allDates.find(([_, values]) =>
      compareDeep(values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent, eventData)
    )?.[0];

    const currentEventIndex = allDates.findIndex(([date]) => date === currentEventDate);

    if (changeType === ViewChangeType.Next) {
      const nextEventIndex = allDates
        .slice(currentEventIndex + 1)
        .findIndex(
          ([, values]) =>
            values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent.length > 1
        );

      if (nextEventIndex !== -1) {
        const nextEventDate = allDates[currentEventIndex + 1 + nextEventIndex][0];
        const nextEventData =
          nextEventDate in results.cases[caseId!].forecastDates
            ? results.cases[caseId!].forecastDates[nextEventDate].shareholderDebtInstrumentsEvents[
                instrumentId
              ].periodByEvent
            : nextEventDate in (results.cases[caseId!].historicDates.historicEventYears ?? {})
            ? results?.cases?.[caseId!]?.historicDates?.historicEventYears?.[nextEventDate]
                ?.shareholderDebtInstrumentsEvents?.[instrumentId]?.periodByEvent ?? []
            : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
                .periodByEvent;
        setEventData(nextEventData);
        const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
          instrumentId,
          nextEventDate
        );
        setShouldHidePrevious(shouldHidePrevious);
        setShouldHideNext(shouldHideNext);
      }
    } else {
      const previousEventIndex = allDates
        .slice(0, currentEventIndex)
        .reverse()
        .findIndex(
          ([, values]) =>
            values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent.length > 1
        );

      if (previousEventIndex !== -1) {
        const previousEventDate = allDates[currentEventIndex - 1 - previousEventIndex][0];
        const previousEventData =
          previousEventDate in results.cases[caseId!].forecastDates
            ? results.cases[caseId!].forecastDates[previousEventDate]
                .shareholderDebtInstrumentsEvents[instrumentId].periodByEvent
            : previousEventDate in (results.cases[caseId!].historicDates.historicEventYears ?? {})
            ? results?.cases?.[caseId!]?.historicDates?.historicEventYears?.[previousEventDate]
                ?.shareholderDebtInstrumentsEvents?.[instrumentId]?.periodByEvent ?? []
            : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
                .periodByEvent;
        setEventData(previousEventData);
        const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
          instrumentId,
          previousEventDate
        );
        setShouldHidePrevious(shouldHidePrevious);
        setShouldHideNext(shouldHideNext);
      }
    }
  };

  const createTableRow = (
    title: translationKeys,
    key: keyof Pwerm2InputYearDebtInstrumentCalculationResultsPeriodTotalDto,
    instrumentId: string,
    isBold = false,
    isPercentage = false,
    isText = false
  ) => {
    return (
      <tr>
        <th className="table-primary__cell--vertical-separator">
          {isBold ? <strong>{title}</strong> : title}
        </th>
        <DealThesisEmptyHistoricDataColumns isHistoricEventsSection />
        {showHistoricDataColumns &&
          historicEventsData.map((historicYear) => {
            return (
              <td key={historicYear.historicEventsYear}>
                <CellValue
                  value={
                    key === 'couponType'
                      ? mapCouponTypeInterestToTranslation(
                          historicYear.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal
                            .couponType
                        )
                      : historicYear.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal[
                          key
                        ]
                  }
                  {...(isText ? {} : amountRules)}
                  {...(isPercentage ? enteredPercentageViewRules : {})}
                  strong={isBold}
                  alignment={Alignment.Right}
                />
              </td>
            );
          })}
        <td>
          <CellValue
            value={
              key === 'couponType'
                ? mapCouponTypeInterestToTranslation(
                    results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                      instrumentId
                    ]?.periodTotal.couponType
                  )
                : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                    instrumentId
                  ]?.periodTotal[key]
            }
            {...(isText ? {} : amountRules)}
            {...(isPercentage ? enteredPercentageViewRules : {})}
            strong={isBold}
            alignment={Alignment.Right}
          />
        </td>
        {caseForecasts.map(([forecastDate, forecastValue]) => {
          const value =
            key === 'couponType'
              ? mapCouponTypeInterestToTranslation(
                  forecastValue.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal
                    .couponType
                )
              : forecastValue.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal[key];
          const instrumentExists = value !== null && value !== undefined;
          return (
            <td
              key={forecastDate}
              className={classNames('', {
                [styles['non-existent-instrument-value']]: !instrumentExists,
              })}>
              {instrumentExists && (
                <CellValue
                  value={value}
                  {...(isText ? {} : amountRules)}
                  {...(isPercentage ? enteredPercentageViewRules : {})}
                  strong={isBold}
                  alignment={Alignment.Right}
                />
              )}
            </td>
          );
        })}
        <td />
      </tr>
    );
  };

  return !fields.length ? (
    <></>
  ) : (
    <>
      <tbody>
        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--header">{l('_ShareholderDebtInstruments')}</th>
          <td className="table-primary__cell--header" colSpan={sectionColCount - 1} />
        </tr>
        {fields.map((instrument) => {
          const isInstrumentPresentAcrossYears = Object.values(
            results.cases[caseId!].forecastDates
          ).some((y) => instrument.instrumentId in y.shareholderDebtInstrumentsEvents);
          if (!isInstrumentPresentAcrossYears) {
            return <Fragment key={instrument.instrumentId} />;
          }
          return (
            <Fragment key={instrument.id}>
              <tr className="table-primary__row--header">
                <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                  {instrument.instrumentNarrative}
                </th>
                <DealThesisEmptyHistoricDataColumns
                  isHistoricEventsSection
                  isStartSeparator
                  isEndSeparator
                />
                {showHistoricDataColumns &&
                  historicEventsData.map((historicYear) => {
                    return (
                      <td
                        key={historicYear.historicEventsYear}
                        className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                        {historicYear.shareholderDebtInstrumentsEvents[instrument.instrumentId]
                          ?.periodByEvent.length > 1 && (
                          <Button
                            className={styles['corkscrew-modal-icon']}
                            startIcon={<SvgAnalysis />}
                            onClick={() =>
                              handleCorkscrewButtonClick(
                                true,
                                instrument.instrumentId,
                                instrument.instrumentNarrative,
                                historicYear.shareholderDebtInstrumentsEvents[
                                  instrument.instrumentId
                                ].periodByEvent,
                                historicYear.historicEventsYear
                              )
                            }
                            autoIconSize
                            appearance={ButtonAppearance.CLEAN}
                            size={ButtonSize.SMALL}
                          />
                        )}
                      </td>
                    );
                  })}
                <td className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                  {results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                    instrument.instrumentId
                  ]?.periodByEvent.length > 1 && (
                    <Button
                      className={styles['corkscrew-modal-icon']}
                      startIcon={<SvgAnalysis />}
                      onClick={() =>
                        handleCorkscrewButtonClick(
                          true,
                          instrument.instrumentId,
                          instrument.instrumentNarrative,
                          results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                            instrument.instrumentId
                          ]?.periodByEvent,
                          project.valuationDate
                        )
                      }
                      autoIconSize
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.SMALL}
                    />
                  )}
                </td>
                {caseForecasts.map(([forecastDate, forecastValues]) => {
                  return (
                    <td
                      key={forecastDate}
                      className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                      {forecastValues.shareholderDebtInstrumentsEvents[instrument.instrumentId]
                        ?.periodByEvent.length > 1 && (
                        <Button
                          className={styles['corkscrew-modal-icon']}
                          startIcon={<SvgAnalysis />}
                          onClick={() =>
                            handleCorkscrewButtonClick(
                              true,
                              instrument.instrumentId,
                              instrument.instrumentNarrative,
                              forecastValues.shareholderDebtInstrumentsEvents[
                                instrument.instrumentId
                              ].periodByEvent,
                              forecastDate
                            )
                          }
                          autoIconSize
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.SMALL}
                        />
                      )}
                    </td>
                  );
                })}
                <td className="table-primary__cell--header-secondary table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
              </tr>
              {createTableRow(
                l('_OpeningBalance'),
                'openingBalance',
                instrument.instrumentId,
                true
              )}
              {createTableRow(l('_AdditionOrRedemption'), 'additions', instrument.instrumentId)}
              {createTableRow(l('_AccruedInterest'), 'accruedInterest', instrument.instrumentId)}
              {createTableRow(
                l('_ClosingBalance'),
                'closingBalance',
                instrument.instrumentId,
                true
              )}
              {createTableRow(l('_CouponPc'), 'couponPc', instrument.instrumentId, false, true)}
              {createTableRow(
                l('_CouponType'),
                'couponType',
                instrument.instrumentId,
                false,
                false,
                true
              )}
              {createTableRow(
                l('_ProportionOfCashPay'),
                'cashPayProportionPc',
                instrument.instrumentId,
                false,
                true
              )}
              {createTableRow(
                l('_CashPayInterest'),
                'cashPayInterest',
                instrument.instrumentId,
                false,
                false
              )}
              <DealThesisSpacerRow2 />
            </Fragment>
          );
        })}
      </tbody>
      <CorkscrewModal
        isOpen={isCorkscrewModalOpen}
        setIsOpen={setIsCorkscrewModalOpen}
        instrumentId={instrumentId}
        instrumentNarrative={instrumentNarrative}
        eventData={eventData}
        shouldHidePrevious={shouldHidePrevious}
        shouldHideNext={shouldHideNext}
        handleCorkscrewForecastChange={handleCorkscrewForecastChange}
      />
    </>
  );
};
