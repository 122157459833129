import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  amountWithDecimalRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { currencyDenominationPair } from '@app/shared/helpers';
import SvgChevron from '@app/shared/icons/Chevron';
import {
  CapitalStructureEventDto,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './expand.module.scss';

interface ExpandInstrumentTableProps {
  buildStructureEvent: CapitalStructureEventDto;
  expandedInstruments: Dictionary<boolean>;
  setExpandedInstruments: Dispatch<SetStateAction<Dictionary<boolean>>>;
  instrumentDefinitions: Record<string, CapitalStructureInstrumentDefinitionDto>;
}

export const ExpandInstrumentTable: FC<ExpandInstrumentTableProps> = ({
  expandedInstruments,
  setExpandedInstruments,
  buildStructureEvent,
  instrumentDefinitions,
}) => {
  const {
    details: { currency, denomination },
  } = useAppSelector((state) => state.project.projectDraft);

  const { l } = useLocale();

  const isMovementEmpty =
    buildStructureEvent.movements.adjustments.total.amount === 0 &&
    buildStructureEvent.movements.adjustments.total.numberOfShares === 0;

  return (
    <>
      {isMovementEmpty && <p className={styles['table']}>{l('_ThereAreNoMovementsInThisEvent')}</p>}
      {!isMovementEmpty && (
        <table className={styles['table']}>
          <thead>
            <tr>
              <th />
              <th className={styles['upper-header']}>
                <CellValue
                  strong
                  alignment={Alignment.Right}
                  value={currencyDenominationPair(currency, denomination)}
                />
              </th>
              <th className={styles['upper-header']}>
                <CellValue strong alignment={Alignment.Right} value={l('_CountSymbol')} />
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>{l('_ChangesInInvestment')}</th>
              <th>
                <CellValue
                  {...amountRules}
                  value={buildStructureEvent.movements.adjustments.total.amount}
                />
              </th>
              <th>
                <CellValue
                  {...amountWithDecimalRules}
                  value={buildStructureEvent.movements.adjustments.total.numberOfShares}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(buildStructureEvent.movements.adjustments.instrumentTypeSummary).map(
              ([instrumentTypeKey, values]) => {
                const instrumentType =
                  InstrumentType[instrumentTypeKey as keyof typeof InstrumentType];
                return (
                  <tr key={instrumentTypeKey}>
                    <td>{instrumentType}</td>
                    <td>
                      <CellValue {...amountRules} value={values.total.amount} />
                    </td>
                    <td>
                      <CellValue {...amountWithDecimalRules} value={values.total.numberOfShares} />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
          <tbody>
            <tr>
              <td>
                <div className={styles['tbody-separator']} />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>{l('_ChangesInInvestmentByHolders')}</th>
              <th>
                <CellValue
                  {...amountRules}
                  value={buildStructureEvent.movements.adjustments.total.amount}
                />
              </th>
              <th>
                <CellValue
                  {...amountWithDecimalRules}
                  value={buildStructureEvent.movements.adjustments.total.numberOfShares}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(buildStructureEvent.movements.adjustments.ownerTypeSummary).map(
              ([ownerKey, values]) => {
                const ownerType = OwnerType[ownerKey as keyof typeof OwnerType];
                return (
                  <tr key={ownerKey}>
                    <td>{ownerType}</td>
                    <td>
                      <CellValue {...amountRules} value={values.amount} />
                    </td>
                    <td>
                      <CellValue {...amountWithDecimalRules} value={values.numberOfShares} />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
          <tbody>
            <tr>
              <td>
                <div className={styles['tbody-separator']} />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>{l('_InvestmentByInstrument')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(buildStructureEvent.movements.adjustments.instrumentTypeSummary).map(
              ([instrumentTypeKey, values]) => {
                const instrumentType =
                  InstrumentType[instrumentTypeKey as keyof typeof InstrumentType];

                return (
                  <React.Fragment key={instrumentTypeKey + '-details'}>
                    <tr key={instrumentTypeKey + '-details'}>
                      <td>{instrumentType}</td>
                      <td>
                        <CellValue {...amountRules} value={values.total.amount} />
                      </td>
                      <td>
                        <CellValue
                          {...amountWithDecimalRules}
                          value={values.total.numberOfShares}
                        />
                      </td>
                    </tr>
                    {Object.entries(values.totalInstrument).map(([instrumentKey, instrument]) => {
                      return (
                        <React.Fragment key={instrumentType + '-' + instrumentKey}>
                          <tr key={instrumentType + '-' + instrumentKey}>
                            <td className={styles['instrument']}>
                              <div className={styles['chevron-wrapper']}>
                                <SvgChevron
                                  onClick={() => {
                                    setExpandedInstruments({
                                      ...expandedInstruments,
                                      [instrumentKey]: !expandedInstruments[instrumentKey],
                                    });
                                  }}
                                  className={classNames(styles['chevron'], {
                                    [styles['chevron__collapsed']]:
                                      !expandedInstruments[instrumentKey],
                                    [styles['chevron__expanded']]:
                                      expandedInstruments[instrumentKey],
                                  })}
                                />
                                {instrumentDefinitions[instrumentKey].instrumentNarrative}
                              </div>
                            </td>
                            <td>
                              <CellValue {...amountRules} value={instrument.total.amount} />
                            </td>
                            <td>
                              <CellValue
                                {...amountWithDecimalRules}
                                value={instrument.total.numberOfShares}
                              />
                            </td>
                          </tr>
                          {expandedInstruments[instrumentKey] &&
                            Object.entries(instrument.totalOwnershipType).map(
                              ([ownerKey, owner]) => {
                                const ownerType = OwnerType[ownerKey as keyof typeof OwnerType];
                                return (
                                  <React.Fragment key={instrumentKey + '-' + ownerKey}>
                                    <tr key={instrumentKey + '-' + ownerKey}>
                                      <td className={styles['owner']}>{ownerType}</td>
                                      <td>
                                        <CellValue {...amountRules} value={owner.amount} italics />
                                      </td>
                                      <td>
                                        <CellValue
                                          {...amountRules}
                                          value={owner.numberOfShares}
                                          italics
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              }
            )}
          </tbody>
        </table>
      )}
    </>
  );
};
