import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { enumKeyByValue, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import {
  Operator,
  PayoutDriver,
  TargetMetric,
  WaterfallSectionsErf,
  WaterfallSections,
} from '@app/shared/models/contracts/enums/shared-enums';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { TextTransform } from '@app/shared/enums/text-transform.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import classNames from 'classnames';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getWaterfallCaseYearInstrumentFirstTrancheValue,
  getWaterfallCaseYearInstrumentTrancheConditionValue,
  getWaterfallCaseYearInstrumentTrancheValue,
  getWaterfallCaseYearInstrumentValue,
  getWaterfallCaseYearMultipleInstrumentFirstTrancheValue,
  getWaterfallCaseYearMultipleInstrumentTrancheValue,
  getWaterfallCaseYearMultipleInstrumentValue,
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { Pwerm2WaterfallTotalPayoutSummaryPartialTable } from '../total-payout-summary/Pwerm2WaterfallTotalPayoutSummaryPartialTable';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters/number-value-formatter';
import { selectOrdinaryEquityInstruments } from '@app/core/store/capital-structure-slice-selectors';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import useTogglerPwermWaterfall from '@app/core/hooks/useTogglerPwermWaterfall';

export const Pwerm2WaterfallOrdinaryEquityPartialTable: FC<Pwerm2WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );
  const interpolationOption = {
    escapeValue: false,
  };
  const equityInstruments = useAppSelector(selectOrdinaryEquityInstruments);
  const { handleToggle } = useTogglerPwermWaterfall(WaterfallSectionsErf.OrdinaryEquity);
  const { l } = useLocale();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_OrdinaryEquityTitle')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.OrdinaryEquity)}
          isOpen={pwermWaterfallTableStates[WaterfallSectionsErf.OrdinaryEquity]}
          setIsOpen={handleToggle}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed':
            !pwermWaterfallTableStates[WaterfallSectionsErf.OrdinaryEquity],
        })}>
        <TableSectionValuesPerMultiple
          title={l('_OrdinaryEquity')}
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.totalOrdinaryEquityProceeds
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerForecast
          title={l('_WeightedOrdinaryEquity')}
          highlight
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.weightedTotalOrdinaryEquityProceeds
              )}
            />
          )}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
        {equityInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn).map((instrument) => (
          <Fragment key={instrument.instrumentId}>
            <tr>
              <th className="table-primary__cell--header-quaternary">
                {instrument.instrumentNarrative}
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-quaternary" />
            </tr>
            {instrument.payoutLogic?.length === 0 && (
              <>
                <TableSectionValuesPerForecast
                  title={l('_PcShareholding')}
                  renderCell={({ caseItem, forecast }) => {
                    // required as each instrument contains at least one tranche, and this
                    // relates to a scenario where the source implies that there are no tranches
                    const value = getWaterfallCaseYearInstrumentFirstTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (tranche) => tranche.shareholdingPc
                    );

                    if (value !== null) {
                      return (
                        <CellValue
                          {...enteredPercentageViewRules}
                          value={getCalculatedProjectValueInPercent(value)}
                        />
                      );
                    }

                    return <></>;
                  }}
                  computedCellClassNames={({ caseItem, forecast }) => {
                    const value = getWaterfallCaseYearInstrumentFirstTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (tranche) => tranche.shareholdingPc
                    );

                    return value === null ? styles['non-existent-instrument-value'] : '';
                  }}
                />
                <TableSectionValuesPerMultiple
                  title={l('_PcParticipation')}
                  renderCell={({ caseItem, forecast, multipleId }) => {
                    // required as each instrument contains at least one tranche, and this
                    // relates to a scenario where the source implies that there are no tranches
                    const value = getWaterfallCaseYearMultipleInstrumentFirstTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      multipleId!,
                      instrument.instrumentId,
                      (tranche) => tranche.totalParticipationPc
                    );

                    if (value !== null) {
                      return (
                        <CellValue
                          {...enteredPercentageViewRules}
                          value={getCalculatedProjectValueInPercent(value)}
                        />
                      );
                    }

                    return <></>;
                  }}
                  computedCellClassNames={({ caseItem, forecast, multipleId }) => {
                    const value = getWaterfallCaseYearMultipleInstrumentFirstTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      multipleId!,
                      instrument.instrumentId,
                      (tranche) => tranche.totalParticipationPc
                    );

                    return value === null ? styles['non-existent-instrument-value'] : '';
                  }}
                />
                <TableSectionValuesPerMultiple
                  title={l('_DistributionsPaid')}
                  renderCell={({ caseItem, forecast, multipleId }) => {
                    const value = getWaterfallCaseYearMultipleInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      multipleId!,
                      instrument.instrumentId,
                      (instrument) => instrument.totalDistributions
                    );

                    if (value !== null) {
                      return <CellValue {...amountRules} value={value} />;
                    }

                    return <></>;
                  }}
                  computedCellClassNames={({ caseItem, forecast, multipleId }) => {
                    const value = getWaterfallCaseYearMultipleInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      multipleId!,
                      instrument.instrumentId,
                      (instrument) => instrument.totalDistributions
                    );

                    return value === null ? styles['non-existent-instrument-value'] : '';
                  }}
                />
                <TableSectionValuesPerForecast
                  title={l('_WeightedDistributions')}
                  highlight
                  additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  renderCell={({ caseItem, forecast }) => {
                    const value = getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.weightedTotalDistributions
                    );

                    if (value !== null) {
                      return <CellValue {...amountRules} strong value={value} />;
                    }

                    return <></>;
                  }}
                  computedCellClassNames={({ caseItem, forecast }) => {
                    const value = getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.weightedTotalDistributions
                    );

                    return value === null ? styles['non-existent-instrument-value'] : '';
                  }}
                />
              </>
            )}
            {(instrument.payoutLogic?.length ?? 0) > 0 && (
              <>
                {instrument.payoutLogic!.map((tranche, index) => {
                  const trancheHasIRR = tranche.conditions.some(
                    (c) =>
                      c.targetMetric === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                  );

                  const trancheHasMoM = tranche.conditions.some(
                    (c) =>
                      c.targetMetric === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
                  );

                  const trancheHasConjunction = Boolean(tranche.conjunction);

                  const isIncrementalHurdleUsed =
                    tranche.payoutCalculation.payoutDriver ===
                    enumKeyByValue(PayoutDriver, PayoutDriver.Incremental);

                  return (
                    <Fragment key={tranche.id}>
                      <tr>
                        <th className="table-primary__cell--header-tertiary">
                          {l('_TrancheN', { n: index + 1 })}
                        </th>
                        <th
                          colSpan={columnsCount - 1}
                          className="table-primary__cell--header-tertiary"
                        />
                      </tr>
                      {(trancheHasIRR || trancheHasMoM) && (
                        <>
                          <TableSectionValuesPerForecast
                            title={l('_SponsorCost')}
                            renderSpacer={false}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...amountRules}
                                value={getWaterfallCaseYearValue(
                                  results,
                                  caseItem.caseId,
                                  forecast.forecastId,
                                  (year) => year.institutionalInvestment
                                )}
                              />
                            )}
                          />
                          {tranche.conditions
                            .filter(
                              (x) =>
                                x.targetMetric ===
                                  enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR) ||
                                x.targetMetric ===
                                  enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
                            )
                            .map((condition, conditionIndex) => {
                              const isIrr =
                                condition.targetMetric ===
                                enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR);
                              const formatterProps = isIrr
                                ? percentageValueFormatterProps
                                : calculatedMultipleValueFormatterProps;
                              const value = numberValueFormatter({
                                value: Number(condition.targetMetricTestValue),
                                ...formatterProps,
                              });
                              const title = l('_SponsorConditionPlaceholders', {
                                metric: isIrr ? l('_IRR') : l('_MoM'),
                                operator: Operator[condition.operator],
                                value,
                                interpolation: { escapeValue: false },
                              });
                              return (
                                <TableSectionValuesPerForecast
                                  key={conditionIndex}
                                  renderSpacer={false}
                                  title={title}
                                  renderCell={({ caseItem, forecast }) => (
                                    <CellValue
                                      {...amountRules}
                                      value={getWaterfallCaseYearInstrumentTrancheConditionValue(
                                        results,
                                        caseItem.caseId,
                                        forecast.forecastId,
                                        instrument.instrumentId,
                                        tranche.id,
                                        conditionIndex,
                                        (condition) => condition.equivalentHurdleValue
                                      )}
                                    />
                                  )}
                                />
                              );
                            })}

                          {trancheHasConjunction && (
                            <TableSectionValuesPerForecast
                              renderSpacer={false}
                              title={l('_InstitutionHurdleActive')}
                              renderCell={({ caseItem, forecast }) => (
                                <CellValue
                                  {...amountRules}
                                  value={getWaterfallCaseYearInstrumentTrancheValue(
                                    results,
                                    caseItem.caseId,
                                    forecast.forecastId,
                                    instrument.instrumentId,
                                    tranche.id,
                                    (tranche) => tranche.activeHurdle
                                  )}
                                />
                              )}
                            />
                          )}
                          <TableSectionValuesPerForecast
                            title={l('_LessSponsorDebt')}
                            renderSpacer={false}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...amountRules}
                                value={getWaterfallCaseYearInstrumentTrancheValue(
                                  results,
                                  caseItem.caseId,
                                  forecast.forecastId,
                                  instrument.instrumentId,
                                  tranche.id,
                                  (tranche) => tranche.lessSponsorDebt
                                )}
                              />
                            )}
                          />
                          <TableSectionValuesPerForecast
                            title={l('_HurdleRemaining')}
                            renderSpacer={false}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...amountRules}
                                value={getWaterfallCaseYearInstrumentTrancheValue(
                                  results,
                                  caseItem.caseId,
                                  forecast.forecastId,
                                  instrument.instrumentId,
                                  tranche.id,
                                  (tranche) => tranche.hurdleRemaining
                                )}
                              />
                            )}
                          />
                          <TableSectionValuesPerForecast
                            title={l('_GrossUpPc')}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={getCalculatedProjectValueInPercent(
                                  getWaterfallCaseYearInstrumentTrancheValue(
                                    results,
                                    caseItem.caseId,
                                    forecast.forecastId,
                                    instrument.instrumentId,
                                    tranche.id,
                                    (tranche) => tranche.grossUp
                                  )
                                )}
                              />
                            )}
                          />
                        </>
                      )}
                      <TableSectionValuesPerForecast
                        title={l('_OrdinaryEquityHurdle')}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getWaterfallCaseYearInstrumentTrancheValue(
                              results,
                              caseItem.caseId,
                              forecast.forecastId,
                              instrument.instrumentId,
                              tranche.id,
                              (tranche) => tranche.ordinaryEquityHurdle
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerMultiple
                        title={l('_IsHurdleMet')}
                        renderCell={({ caseItem, forecast, multipleId }) => {
                          const isHurdleMetValue =
                            getWaterfallCaseYearMultipleInstrumentTrancheValue(
                              results,
                              caseItem.caseId,
                              forecast.forecastId,
                              multipleId!,
                              instrument.instrumentId,
                              tranche.id,
                              (tranche) => tranche.isHurdleMet
                            );
                          return (
                            <CellValue
                              className={
                                isHurdleMetValue
                                  ? styles['upper-text']
                                  : styles['upper-text-value-false']
                              }
                              alignment={Alignment.Right}
                              textTransform={TextTransform.Uppercase}
                              value={isHurdleMetValue}
                            />
                          );
                        }}
                      />
                      {isIncrementalHurdleUsed && (
                        <TableSectionValuesPerMultiple
                          title={l('_OrdinaryEquityPostHurdle')}
                          renderCell={({ caseItem, forecast, multipleId }) => (
                            <CellValue
                              {...amountRules}
                              value={getWaterfallCaseYearMultipleInstrumentTrancheValue(
                                results,
                                caseItem.caseId,
                                forecast.forecastId,
                                multipleId!,
                                instrument.instrumentId,
                                tranche.id,
                                (tranche) => tranche.ordinaryEquityPostHurdle
                              )}
                            />
                          )}
                        />
                      )}
                      <TableSectionValuesPerMultiple
                        title={l('_PcParticipation')}
                        renderCell={({ caseItem, forecast, multipleId }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              Number(
                                getWaterfallCaseYearMultipleInstrumentTrancheValue(
                                  results,
                                  caseItem.caseId,
                                  forecast.forecastId,
                                  multipleId!,
                                  instrument.instrumentId,
                                  tranche.id,
                                  (tranche) => tranche.totalParticipationPc
                                )
                              )
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerMultiple
                        title={l('_DistributionsPaid')}
                        renderCell={({ caseItem, forecast, multipleId }) => (
                          <CellValue
                            {...amountRules}
                            value={getWaterfallCaseYearMultipleInstrumentTrancheValue(
                              results,
                              caseItem.caseId,
                              forecast.forecastId,
                              multipleId!,
                              instrument.instrumentId,
                              tranche.id,
                              (tranche) => tranche.totalDistributions
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_WeightedDistributions')}
                        highlight
                        renderSpacer={false}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getWaterfallCaseYearInstrumentTrancheValue(
                              results,
                              caseItem.caseId,
                              forecast.forecastId,
                              instrument.instrumentId,
                              tranche.id,
                              (tranche) => tranche.weightedTotalDistributions
                            )}
                          />
                        )}
                      />
                      <TableSpacerRow />
                    </Fragment>
                  );
                })}
                {(instrument.payoutLogic?.length ?? 0) > 1 ? (
                  <>
                    <tr>
                      <th className="table-primary__cell--header-tertiary">
                        {l('_TotalDistributionsToName', {
                          name: instrument.instrumentNarrative,
                          interpolation: interpolationOption,
                        })}
                      </th>
                      <th
                        className="table-primary__cell--header-tertiary"
                        colSpan={columnsCount - 1}
                      />
                    </tr>
                    <TableSectionValuesPerMultiple
                      title={l('_TotalDistributions')}
                      renderCell={({ caseItem, forecast, multipleId }) => (
                        <CellValue
                          {...amountRules}
                          value={getWaterfallCaseYearMultipleInstrumentValue(
                            results,
                            caseItem.caseId,
                            forecast.forecastId,
                            multipleId!,
                            instrument.instrumentId,
                            (instrument) => instrument.totalDistributions
                          )}
                        />
                      )}
                    />
                    <TableSectionValuesPerForecast
                      title={l('_WeightedDistributions')}
                      highlight
                      additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      renderCell={({ caseItem, forecast }) => (
                        <CellValue
                          {...amountRules}
                          value={getWaterfallCaseYearInstrumentValue(
                            results,
                            caseItem.caseId,
                            forecast.forecastId,
                            instrument.instrumentId,
                            (instrument) => instrument.weightedTotalDistributions
                          )}
                        />
                      )}
                    />
                  </>
                ) : undefined}
              </>
            )}
          </Fragment>
        ))}
        <Pwerm2WaterfallTotalPayoutSummaryPartialTable columnsCount={columnsCount} />
      </tbody>
    </>
  );
};
