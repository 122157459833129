import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { selectProjectDraft } from './project-slice-selectors';

const selectUiValues = (state: RootState) => state.uiValues;

export const selectMaintenanceModeSettings = createSelector([selectUiValues], (uiValues) => {
  return uiValues.maintenance;
});

export const selectProjectCompatibilityLevels = createSelector([selectUiValues], (uiValues) => {
  return uiValues.compatibilityLevel.levels;
});

export const selectCurrentProjectCompatibilityLevel = createSelector(
  [selectProjectDraft, selectProjectCompatibilityLevels],
  (projectDraft, compatibilityLevels) => {
    const sortedLevels = [...compatibilityLevels].sort((a, b) => a.sequence - b.sequence);
    const projectLevel = sortedLevels.find(
      (level) => level.compatibilityLevel === projectDraft.compatibilityLevel
    );

    return projectLevel;
  }
);

export const selectUpgradePathCompatibilityLevels = createSelector(
  [selectProjectCompatibilityLevels, selectCurrentProjectCompatibilityLevel],
  (compatibilityLevels, currentLevel) => {
    const sortedLevels = [...compatibilityLevels].sort((a, b) => a.sequence - b.sequence);
    const currentLevelIndex = sortedLevels.findIndex(
      (level) => level.compatibilityLevel === currentLevel?.compatibilityLevel
    );

    if (currentLevelIndex === -1) {
      return [];
    }

    return sortedLevels.slice(currentLevelIndex + 1);
  }
);

export const selectLatestProjectCompatibilityLevel = createSelector(
  [selectProjectCompatibilityLevels],
  (compatibilityLevels) => {
    const sortedLevels = [...compatibilityLevels].sort((a, b) => a.sequence - b.sequence);
    return sortedLevels.pop();
  }
);
