import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './card.module.scss';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { FocusTrap } from '@mui/base';

interface CardProps extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  hasShadow?: boolean;
  variation?: CardVariation;
  cornersVariation?: CardCornersVariation;
  isFullHeight?: boolean;
  shouldFitContent?: boolean;
  trapFocus?: boolean;
}

const PARENT_CLASSNAME = 'card';

export const Card: FC<CardProps> = ({
  children,
  className,
  variation,
  cornersVariation,
  hasShadow,
  shouldFitContent,
  isFullHeight,
  trapFocus = false,
  ...rest
}): JSX.Element => {
  const cardContent = (
    <div
      className={classNames(className, styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--has-shadow`]]: hasShadow,
        [styles[`${PARENT_CLASSNAME}--fit-content`]]: shouldFitContent,
        [styles[`${PARENT_CLASSNAME}--is-full-height`]]: isFullHeight,
        [styles[`${PARENT_CLASSNAME}--${variation}`]]: variation,
        [styles[`${PARENT_CLASSNAME}--${cornersVariation}-radius`]]: cornersVariation,
      })}
      {...rest}>
      {children}
    </div>
  );

  return trapFocus ? <FocusTrap open>{cardContent}</FocusTrap> : cardContent;
};
