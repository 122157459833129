import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useShareholderDebtSectionCheck = () => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const { caseId } = useParams();
  const firstForecast = Object.values(results.cases[caseId!].forecastDates)[0];

  const showSlnSection = useMemo(
    () =>
      Object.keys(firstForecast.shareholderDebtSummary).some(
        (i) => i === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
      ),
    [firstForecast]
  );

  const showPrefSharesSection = useMemo(
    () =>
      Object.keys(firstForecast.shareholderDebtSummary).some(
        (i) => i === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
      ),
    [firstForecast]
  );

  const showShareholderDebtSection = useMemo(
    () => showSlnSection || showPrefSharesSection,
    [showSlnSection, showPrefSharesSection]
  );

  return { showSlnSection, showPrefSharesSection, showShareholderDebtSection };
};

export default useShareholderDebtSectionCheck;
