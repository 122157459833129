import { useAppDispatch, useAppSelector } from './redux-hooks';
import { setPwermWaterfallTableCollapseState } from '../store/ui-values-slice';

const useTogglerPwermWaterfall = (sectionKey: string) => {
  const dispatch = useAppDispatch();
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );

  const handleToggle = () => {
    const newState = !pwermWaterfallTableStates[sectionKey];
    dispatch(
      setPwermWaterfallTableCollapseState({
        key: sectionKey,
        value: newState,
      })
    );
  };

  return { handleToggle };
};

export default useTogglerPwermWaterfall;
