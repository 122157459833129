import { FC, Fragment } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { enumKeyByValue, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import {
  InstrumentType,
  TooltipWidth,
  WaterfallSections,
  WaterfallSectionsErf,
} from '@app/shared/models/contracts/enums/shared-enums';
import { PayoutLogicDto } from '@app/shared/models/contracts/project-dto';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import classNames from 'classnames';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { WaterfallPartialTableProps } from '../../pwerm-summary-waterfall/waterfall-table/waterfall-shared';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import {
  getWaterfallCaseInstrumentTrancheValue,
  getWaterfallCaseInstrumentValue,
  getWaterfallCaseYearInstrumentFirstTrancheValue,
  getWaterfallCaseYearInstrumentTrancheValue,
  getWaterfallCaseYearInstrumentTransactionSummary,
  getWaterfallCaseYearInstrumentTransaction,
  getWaterfallCaseYearInstrumentValue,
  getWaterfallCaseYearValue,
  getWaterfallInstrumentTrancheValue,
  getWaterfallInstrumentValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import TableSectionValuesPerCase from '../../components/TableSectionValuesPerCase';
import {
  InstrumentDefinitionWithId,
  selectValuedInstruments,
} from '@app/core/store/capital-structure-slice-selectors';
import { DevFeature, isDevFeatureEnabled } from '@app/core/utils/dev-feature';
import useTogglerPwermWaterfall from '@app/core/hooks/useTogglerPwermWaterfall';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import styles from './pwerm-2-waterfall-valuation-partial-table.module.scss';
import { Pwerm2CalculationWaterfallCaseYearInstrumentTransactionDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import { dateFormatter } from '@app/shared/formatters';

export const Pwerm2WaterfallValuationPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const isErfProject = buildStructures?.[capitalStructureId]?.isErf;
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };
  const ordinaryEquityKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );
  const { handleToggle } = useTogglerPwermWaterfall(WaterfallSectionsErf.Valuation);

  const renderTooltipContent = (
    instrumentId: string,
    transactions: Pwerm2CalculationWaterfallCaseYearInstrumentTransactionDto[],
    transactionTotal: number | null
  ) => {
    return (
      <>
        {transactions.map((transaction) => {
          const formattedDate = dateFormatter(transaction.date, { dateStyle: 'short' });
          if (transaction.date !== null && transaction.movement !== null) {
            return (
              <table key={`${instrumentId}`}>
                <thead>
                  <tr className="table-primary__row--plain">
                    <th>
                      <strong>{l('_EventDate')}</strong>
                    </th>
                    <th>
                      <strong>{l('_(Addition)/Redemption')}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="table-primary--no-__row--plain"
                    key={`${instrumentId}-${transaction.date}`}>
                    <td>{formattedDate}</td>
                    <td>
                      <CellValue
                        alignment={Alignment.Right}
                        {...amountRules}
                        value={transaction.movement}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                      <strong>{l('_Total')}</strong>
                    </th>
                    <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                      <CellValue
                        alignment={Alignment.Right}
                        {...amountRules}
                        value={transactionTotal}
                        strong
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          }
        })}
      </>
    );
  };

  const renderValuationSection = (
    instrument: InstrumentDefinitionWithId,
    tranche: PayoutLogicDto | undefined,
    trancheIndex: number | undefined
  ) => {
    // making sure there is at least one transaction with a movement value across all cases and years
    const instrumentContainsMovements = Object.values(results.waterfall.cases).some((caseItem) =>
      Object.values(caseItem.years).some((year) => {
        const instrumentTransactions = year.instruments[instrument.instrumentId].transactions;
        return (
          instrumentTransactions &&
          instrumentTransactions.length !== 0 &&
          instrumentTransactions.some((transaction) => transaction.movement !== 0)
        );
      })
    );

    const shouldDisplayMovements =
      instrumentContainsMovements &&
      instrument.type !== ordinaryEquityKey &&
      isErfProject &&
      isDevFeatureEnabled(DevFeature.ERF);

    return (
      <>
        {shouldDisplayMovements && (
          <>
            <TableSectionValuesPerForecast
              title={l('_(Addition)/Redemption')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.totalInterimTransaction
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_DiscountRate')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...decimalCalculatedViewRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.interimTransactionsDiscountRate
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_DiscountFactor')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => {
                const transactions = getWaterfallCaseYearInstrumentTransaction(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId
                );

                const totalInterimTransaction = getWaterfallCaseYearInstrumentTransactionSummary(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (transactionSummary) => transactionSummary.totalInterimTransaction
                );

                const needsTooltip =
                  transactions &&
                  transactions.length !== 0 &&
                  transactions.some((transaction) => transaction.movement !== 0);

                return (
                  <>
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={getCalculatedProjectValueInPercent(
                        getWaterfallCaseYearInstrumentTransactionSummary(
                          results,
                          caseItem.caseId,
                          forecast.forecastId,
                          instrument.instrumentId,
                          (transactionSummary) =>
                            transactionSummary.interimTransactionsDiscountFactor
                        )
                      )}
                    />
                    {needsTooltip && (
                      <Tooltip
                        className={styles['tooltip']}
                        placement={TooltipPlacement.Left}
                        width={`${TooltipWidth.PwermWaterfallValuationsSection}px`}
                        content={renderTooltipContent(
                          instrument.instrumentId,
                          transactions ?? [],
                          totalInterimTransaction ?? null
                        )}>
                        <Button
                          className={styles['tooltip-button']}
                          startIcon={<SvgWarningStatus />}
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.SMALL}
                        />
                      </Tooltip>
                    )}
                  </>
                );
              }}
            />
            <TableSectionValuesPerForecast
              title={l('_PVof(Addition)/Redemption')}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.interimTransactionsWeightedFairValue
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_CashInterest')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.totalCashPay
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_DiscountRate')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...decimalCalculatedViewRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.cashPayInterestDiscountRate
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_DiscountFactor')}
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...enteredPercentageViewRules}
                  value={getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentTransactionSummary(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (transactionSummary) => transactionSummary.cashPayInterestDiscountFactor
                    )
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_PVofCashInterest')}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.cashPayInterestWeightedFairValue
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_TotalPVofInterimCashflowMovement')}
              additionalTitleClassNames="table-primary__cell--strong"
              renderSpacer={false}
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  className="table-primary__cell--strong"
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) => transactionSummary.interimTransactionsWeightedFairValue
                  )}
                />
              )}
            />
            <TableSectionValuesPerForecast
              title={l('_CumulativePvOfInterimCashflowMovement')}
              additionalTitleClassNames="table-primary__cell--strong"
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  className="table-primary__cell--strong"
                  value={getWaterfallCaseYearInstrumentTransactionSummary(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (transactionSummary) =>
                      transactionSummary.cumulativeCashFlowMovementWeightedFairValue
                  )}
                />
              )}
            />
          </>
        )}
        <TableSectionValuesPerForecast
          title={l('_WeightedProceeds')}
          additionalTitleClassNames="table-primary__cell--strong"
          renderSpacer={false}
          highlight
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              className="table-primary__cell--strong"
              {...amountRules}
              value={
                tranche
                  ? getWaterfallCaseYearInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.weightedTotalDistributions
                    )
                  : getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.weightedTotalDistributions
                    )
              }
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_PostIPODLOM')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                Number(
                  getWaterfallCaseYearValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    (forecast) => forecast.weightedPostIpoSaleRestrictionDlomPc
                  )
                )
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_NetExitWeightedProceeds')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...amountRules}
              value={
                tranche
                  ? getWaterfallCaseYearInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.netDistributions
                    )
                  : getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.netDistributions
                    )
              }
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={instrument.type === ordinaryEquityKey ? l('_CostOfEquity') : l('_DiscountRate')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...(instrument.type === ordinaryEquityKey
                ? enteredPercentageViewRules
                : decimalCalculatedViewRules)}
              value={
                instrument.type === ordinaryEquityKey && tranche
                  ? getCalculatedProjectValueInPercent(
                      getWaterfallCaseYearInstrumentTrancheValue(
                        results,
                        caseItem.caseId,
                        forecast.forecastId,
                        instrument.instrumentId,
                        tranche.id,
                        (tranche) => tranche.cumulativeCostOfEquityPc
                      )
                    )
                  : // cumulativeCostOfEquityPc property is not present in CaseYearInstrument,
                    // and so it needs to be obtained from the generated tranche in the results
                    getCalculatedProjectValueInPercent(
                      getWaterfallCaseYearInstrumentFirstTrancheValue(
                        results,
                        caseItem.caseId,
                        forecast.forecastId,
                        instrument.instrumentId,
                        (tranche) => tranche.cumulativeCostOfEquityPc
                      )
                    )
              }
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_DiscountFactor')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => {
            return (
              <CellValue
                {...enteredPercentageViewRules}
                value={
                  tranche
                    ? getCalculatedProjectValueInPercent(
                        getWaterfallCaseYearInstrumentTrancheValue(
                          results,
                          caseItem.caseId,
                          forecast.forecastId,
                          instrument.instrumentId,
                          tranche.id,
                          (tranche) => tranche.cumulativeDiscountFactor
                        )
                      )
                    : // cumulativeDiscountFactor property is not present in CaseYearInstrument,
                      // and so it needs to be obtained from the generated tranche in the results
                      getCalculatedProjectValueInPercent(
                        getWaterfallCaseYearInstrumentFirstTrancheValue(
                          results,
                          caseItem.caseId,
                          forecast.forecastId,
                          instrument.instrumentId,
                          (tranche) => tranche.cumulativeDiscountFactor
                        )
                      )
                }
              />
            );
          }}
        />
        <TableSectionValuesPerForecast
          title={
            tranche
              ? l('_PvOfInstrumentTrancheN', {
                  instrument: instrument.instrumentNarrative,
                  n: trancheIndex,
                  interpolation: interpolationOption,
                })
              : l('_PvOfInstrumentName', {
                  instrument: instrument.instrumentNarrative,
                  interpolation: interpolationOption,
                })
          }
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.fairValue
              )}
            />
          )}
        />
        {instrument.type !== ordinaryEquityKey &&
          isErfProject &&
          isDevFeatureEnabled(DevFeature.ERF) && (
            <TableSectionValuesPerForecast
              title={l('_TotalPVofInstrument', { instrument: instrument.instrumentNarrative })}
              renderSpacer={false}
              additionalTitleClassNames="table-primary__cell--strong"
              renderCell={({ forecast, caseItem }) => (
                <CellValue
                  {...amountRules}
                  className="table-primary__cell--strong"
                  value={getCalculatedProjectValueInPercent(
                    getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.postDiscountWeightedFairValue
                    )
                  )}
                />
              )}
            />
          )}

        <TableSectionValuesPerForecast
          title={l('_MinorityDiscounts')}
          renderSpacer={false}
          additionalTitleClassNames="table-primary__cell--strong"
          renderCell={() => <CellValue value={' '} />}
        />
        {instrument.type === ordinaryEquityKey && (
          <TableSectionValuesPerForecast
            title={l('_DLOC')}
            renderSpacer={false}
            renderCell={({ forecast, caseItem }) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getWaterfallCaseYearInstrumentValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    instrument.instrumentId,
                    (instrument) => instrument.dlocPc
                  )
                )}
              />
            )}
          />
        )}
        <TableSectionValuesPerForecast
          title={l('_DLOM')}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={
                tranche
                  ? getCalculatedProjectValueInPercent(
                      getWaterfallCaseYearInstrumentTrancheValue(
                        results,
                        caseItem.caseId,
                        forecast.forecastId,
                        instrument.instrumentId,
                        tranche.id,
                        (tranche) => tranche.dlomPc
                      )
                    )
                  : getCalculatedProjectValueInPercent(
                      getWaterfallCaseYearInstrumentValue(
                        results,
                        caseItem.caseId,
                        forecast.forecastId,
                        instrument.instrumentId,
                        (instrument) => instrument.dlomPc
                      )
                    )
              }
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_PostDiscountPV')}
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...amountRules}
              value={
                tranche
                  ? getWaterfallCaseYearInstrumentTrancheValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.discountedFairValue
                    )
                  : getWaterfallCaseYearInstrumentValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      instrument.instrumentId,
                      (instrument) => instrument.discountedFairValue
                    )
              }
            />
          )}
        />
        <TableSectionValuesPerCase
          title={
            tranche
              ? l('_MvOfInstrumentTrancheN', {
                  instrument: instrument.instrumentNarrative,
                  n: trancheIndex,
                  interpolation: interpolationOption,
                })
              : l('_MvOfInstrumentName', {
                  instrument: instrument.instrumentNarrative,
                  interpolation: interpolationOption,
                })
          }
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                alignment={Alignment.Center}
                value={
                  tranche
                    ? getWaterfallCaseInstrumentTrancheValue(
                        results,
                        caseItem.caseId,
                        instrument.instrumentId,
                        tranche.id,
                        (tranche) => tranche.weightedDiscountedFairValue
                      )
                    : getWaterfallCaseInstrumentValue(
                        results,
                        caseItem.caseId,
                        instrument.instrumentId,
                        (instrument) => instrument.weightedDiscountedFairValue
                      )
                }
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
            {l('_WeightedAverageMarketValue')}
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
          <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              strong
              value={
                tranche
                  ? getWaterfallInstrumentTrancheValue(
                      results,
                      instrument.instrumentId,
                      tranche.id,
                      (tranche) => tranche.weightedDiscountedFairValue
                    )
                  : getWaterfallInstrumentValue(
                      results,
                      instrument.instrumentId,
                      (instrument) => instrument.weightedDiscountedFairValue
                    )
              }
            />
          </td>
        </tr>
      </>
    );
  };

  const renderValuationInstrumentTotalSection = (instrument: InstrumentDefinitionWithId) => (
    <>
      <tr>
        <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
      </tr>
      <tr>
        <th className="table-primary__cell--header-tertiary">
          {l('_TotalValueOfName', {
            name: instrument.instrumentNarrative,
            interpolation: interpolationOption,
          })}
        </th>
        <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
      </tr>
      <TableSectionValuesPerForecast
        title={l('_TotalProceedsAcrossTranches')}
        additionalTitleClassNames="table-primary__cell--strong"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            className="table-primary__cell--strong"
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.weightedTotalDistributions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PostIPODLOM')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.weightedPostIpoSaleRestrictionDlom
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_NetExitWeightedProceeds')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.netDistributions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_ImpliedDiscountRateAcrossTranches')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.impliedDiscountRate
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_PVOf', {
          Instrument: instrument.instrumentNarrative,
          interpolation: interpolationOption,
        })}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearInstrumentValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              instrument.instrumentId,
              (instrument) => instrument.fairValue
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_ImpliedMinorityDiscounts')}
        renderSpacer={false}
        additionalTitleClassNames="table-primary__cell--strong"
        renderCell={() => <CellValue value={' '} />}
      />
      <TableSectionValuesPerForecast
        title={l('_DLOC')}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getWaterfallCaseYearInstrumentValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                instrument.instrumentId,
                (instrument) => instrument.dlocPc
              )
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_DLOM')}
        renderCell={({ forecast, caseItem }) => {
          return (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getWaterfallCaseYearInstrumentValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  instrument.instrumentId,
                  (instrument) => instrument.dlomPc
                )
              )}
            />
          );
        }}
      />
      <TableSectionValuesPerCase
        renderSpacer={false}
        title={l('_MVOf', {
          Instrument: instrument.instrumentNarrative,
          interpolation: interpolationOption,
        })}
        renderCell={({ caseItem }) => {
          return (
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={getWaterfallCaseInstrumentValue(
                results,
                caseItem.caseId,
                instrument.instrumentId,
                (instrument) => instrument.weightedDiscountedFairValue
              )}
            />
          );
        }}
        additionalTitleClassNames="table-primary__cell--strong"
        additionalCellClassNames="table-primary__cell--strong"
      />
      <tr>
        <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
          {l('_WeightedAverageMarketValue')}
        </th>
        <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
        <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
          <CellValue
            {...amountRules}
            strong
            alignment={Alignment.Center}
            value={getWaterfallInstrumentValue(
              results,
              instrument.instrumentId,
              (instrument) => instrument.weightedDiscountedFairValue
            )}
          />
        </td>
      </tr>
    </>
  );

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_Valuation')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.Valuation)}
          isOpen={pwermWaterfallTableStates[WaterfallSectionsErf.Valuation]}
          setIsOpen={handleToggle}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed':
            !pwermWaterfallTableStates[WaterfallSectionsErf.Valuation],
        })}>
        {valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn).map((instrument) => (
          <Fragment key={`valuation-item-instrument-${instrument.instrumentId}`}>
            <tr>
              <th className="table-primary__cell--header-quaternary">
                {instrument.instrumentNarrative}
              </th>
              <th className="table-primary__cell--header-quaternary" colSpan={columnsCount - 1} />
            </tr>

            {(instrument.type !== ordinaryEquityKey ||
              (instrument.type === ordinaryEquityKey && !instrument.payoutLogic?.length)) && (
              <>{renderValuationSection(instrument, undefined, undefined)}</>
            )}

            {Boolean(instrument.payoutLogic?.length) && (
              <>
                {instrument.payoutLogic!.map((tranche, index) => {
                  return (
                    <Fragment key={`valuation-item-tranche-${tranche.id}`}>
                      {index !== 0 && <TableSpacerRow />}
                      <tr>
                        <th className="table-primary__cell--header-tertiary">
                          {l('_TrancheN', { n: index + 1 })}
                        </th>
                        <th
                          className="table-primary__cell--header-tertiary"
                          colSpan={columnsCount - 1}
                        />
                      </tr>
                      {renderValuationSection(instrument, tranche, index + 1)}
                    </Fragment>
                  );
                })}
                {Boolean(instrument.payoutLogic?.length && instrument.payoutLogic?.length > 1) && (
                  <>{renderValuationInstrumentTotalSection(instrument)}</>
                )}
              </>
            )}
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary table-primary__cell--strong">
                {l('_ValuePerShare')}
              </th>
              <td className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary" />
              <td
                colSpan={columnsCount - 2}
                className="table-primary__cell--background-tertiary table-primary__cell--strong">
                <CellValue
                  {...decimalCalculatedViewRules}
                  alignment={Alignment.Center}
                  value={getWaterfallInstrumentValue(
                    results,
                    instrument.instrumentId,
                    (instrument) => instrument.weightedDiscountedFairValuePerShare
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
            </tr>
          </Fragment>
        ))}
      </tbody>
    </>
  );
};
