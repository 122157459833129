import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  calculatedMultiplierRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { enumKeyByValue } from '@app/shared/helpers';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import {
  WaterfallSectionsErf,
  WaterfallSections,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { WaterfallPartialTableProps } from '../../pwerm-summary-waterfall/waterfall-table/waterfall-shared';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import TableSectionValuesPerCase from '../../components/TableSectionValuesPerCase';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getWaterfallCaseValue,
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import useTogglerPwermWaterfall from '@app/core/hooks/useTogglerPwermWaterfall';

export const Pwerm2WaterfallSponsorReturnPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();
  const pwermWaterfallTableStates = useAppSelector(
    (state) => state.uiValues.pwermWaterfallTableExpandStates
  );
  const { handleToggle } = useTogglerPwermWaterfall(WaterfallSectionsErf.SponsorReturn);

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_SponsorReturnTitle')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.SponsorReturn)}
          isOpen={pwermWaterfallTableStates[WaterfallSectionsErf.SponsorReturn]}
          setIsOpen={handleToggle}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed':
            !pwermWaterfallTableStates[WaterfallSectionsErf.SponsorReturn],
        })}>
        <TableSectionValuesPerMultiple
          title={l('_ShareholderDebt')}
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.institutionalShareholderDebtDistributions
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerMultiple
          title={l('_OrdinaryEquity')}
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.institutionalOrdinaryEquityDistributions
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerMultiple
          title={l('_TotalReturn')}
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.institutionalReturn
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerCase
          title={l('_SponsorReturn')}
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                alignment={Alignment.Center}
                value={getWaterfallCaseValue(
                  results,
                  caseItem.caseId,
                  (caseItem) => caseItem.weightedInstitutionalReturn
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            {l('_SponsorReturnAllCases')}
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={results.waterfall.weightedInstitutionalReturn}
            />
          </td>
        </tr>
        <TableSpacerRow />

        <tr>
          <th className="table-primary__cell--header-tertiary">{l('_SponsorInvestment')}</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        <TableSectionValuesPerForecast
          title={l('_InvestmentCost')}
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => {
            return (
              <CellValue
                {...amountRules}
                value={getWaterfallCaseYearValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.institutionalInvestment
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerForecast
          title={l('_InvestmentDate')}
          renderSpacer={false}
          renderCell={() => (
            <CellValue
              alignment={Alignment.Right}
              dateFormat={{ day: '2-digit', month: '2-digit', year: '2-digit' }}
              value={project.investmentDate}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_InvestmentHorizon')}
          renderCell={({ caseItem, forecast }) => {
            return (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={Math.max(
                  0,
                  Number(
                    getWaterfallCaseYearValue(
                      results,
                      caseItem.caseId,
                      forecast.forecastId,
                      (year) => year.investmentYearsToExit
                    )
                  )
                )}
              />
            );
          }}
        />
        <tr>
          <th className="table-primary__cell--header-tertiary">{l('_SponsorMoMAndIRR')}</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        <TableSectionValuesPerMultiple
          title={l('_MoM')}
          renderCell={({ caseItem, forecast, multipleId }) => (
            <CellValue
              {...calculatedMultiplierRules}
              value={getWaterfallCaseYearMultipleValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                multipleId!,
                (multiple) => multiple.institutionalMoM
              )}
            />
          )}
        />
        <TableSectionValuesPerCase
          title={l('_CaseMoM')}
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...calculatedMultiplierRules}
                alignment={Alignment.Center}
                value={getWaterfallCaseValue(
                  results,
                  caseItem.caseId,
                  (caseItem) => caseItem.weightedMoM
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            {l('_WeightedMoMAllCases')}
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...calculatedMultiplierRules}
              alignment={Alignment.Center}
              value={results.waterfall.weightedMoM}
            />
          </td>
        </tr>
        <TableSpacerRow />
        <TableSectionValuesPerMultiple
          title={l('_IRR')}
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getWaterfallCaseYearMultipleValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    multipleId!,
                    (multiple) => multiple.institutionalIrr
                  )
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerCase
          title={l('_CaseIRR')}
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...enteredPercentageViewRules}
                alignment={Alignment.Center}
                value={getCalculatedProjectValueInPercent(
                  getWaterfallCaseValue(
                    results,
                    caseItem.caseId,
                    (caseItem) => caseItem.weightedIrr
                  )
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            {l('_WeightedIRRAllCases')}
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              value={getCalculatedProjectValueInPercent(results.waterfall.weightedIrr)}
            />
          </td>
        </tr>
        <TableSpacerRow />
      </tbody>
    </>
  );
};
