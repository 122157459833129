/* eslint-disable no-console */
import env from '@environment';

export const updateMaintenanceMode = async ({
  start,
  end,
  message,
  active,
}: {
  start?: string;
  end?: string;
  message?: string;
  active?: boolean;
}) => {
  const url = `${env.apiUrl}/configuration/systemsettings`;
  const body = {
    maintenance: {
      start,
      end,
      message,
      active,
    },
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    switch (response.status) {
      case 200:
        console.log('Maintenance mode settings updated successfully');
        break;
      case 403:
        console.warn('You must be an administrator to update the maintenance mode settings');
        break;
      default:
        console.log('Unexpected status code: ', response.status);
    }
  } catch (error) {
    console.error('Error updating maintenance mode settings: ', error);
  }
};

export const setupMaintenanceModeBinding = () => {
  window.updateMaintenanceMode = updateMaintenanceMode;
};
